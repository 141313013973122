<template>
  <div class="echart-dialog">
    <div class="mask"></div>
    <div class="echart-dialog-content">
      <slot name="header"></slot>
      <i @click="closeDialog" class="el-icon-error"></i>
      <slot name="xm-table"></slot>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EchartDialog",
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="less" scoped>
.echart-dialog {
  &-content {
    position: fixed;
    top: calc(50% - 260px);
    left: calc(50% - 401px);
    background: #091f2f;
    padding: 25px 23px;
    width: 802px;
    height: 488px;
    border: 1px solid #3c87d8;
    z-index: 1001;
    box-shadow: 0 0 10px 4px rgba(#04ffff, 0.3);
    /deep/ .el-form-item__label {
      font-size: 14px;
      font-weight: 300;
      color: #04ffff;
      padding: 0 3px 0 0;
    }
    /deep/ .el-input {
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        background-color: rgba(#13385b, 75%);
        border: 1px solid #3678c0;
        border-radius: 0;
        color: #abd6f0;
      }
    }
    /deep/ .el-button:not(.el-button--text, .el-color-dropdown__btn) {
      width: 79px;
      height: 26px;
      background: rgba(65, 142, 226, 0.2);
      border-color: #3c87d8;
      opacity: 0.98;
      border-radius: 13px;
      font-size: 13px;
      color: #04ffff;
      padding: 0;
      box-shadow: 1px 0px 18px 4px #418ee2 inset;
      border: 1px solid #3c87d8;
      > span {
        display: inline-block;
        width: 100%;
        height: 100%;
        line-height: 26px;
        a {
          width: 100%;
          height: 100%;
          display: inline-block;
        }
      }
    }
    /deep/ .el-table--fit {
      background: transparent;
      border: none;
      .bskHeader {
        background: #091f2f !important;
        color: #04ffff;
        border: none !important;
        .cell {
          font-weight: 400;
        }
      }
      tr {
        background: rgba(#64acd4, 10%);
        color: #abd6f0;
      }
      tr.el-table__row--striped td.el-table__cell {
        background: #091f2f;
      }
      .bskCell {
        color: #abd6f0;
        height: 38px;
      }
      td.el-table__cell,
      th.el-table__cell.is-leaf {
        border-bottom: none;
        font-size: 14px;
        font-weight: 400;
      }
      &::before {
        display: none;
      }
    }
    /deep/ .el-row {
      margin-bottom: 0 !important;
    }
    /deep/ .el-form {
      border-bottom: 1px solid rgba(#50acfe, 34%);
    }
    /deep/ .el-form-item {
      margin-bottom: 15px;
      margin-left: 15px;
    }
    /deep/ .el-input__suffix {
      color: #fff;
    }
    // /deep/ .el-loading-mask {
    //   height: 100px;
    // }
    /deep/ .el-table--enable-row-hover .el-table__body {
      tr:hover > td,
      tr:hover > td.el-table__cell {
        background: rgba(100, 172, 212, 0.1);
      }
      .el-table__row--striped:hover > td,
      .el-table__row--striped:hover > td.el-table__cell {
        background: #091f2f;
      }
    }
    /deep/ .el-table__empty-text {
      > div {
        padding-top: 0 !important;
        color: #04ffff !important;
        line-height: 20px;
        font-size: 13px !important;
        padding-bottom: 0 !important;
        font-weight: 400 !important;
        img {
          width: 200px !important;
          padding-top: 20px;
        }
      }
    }
    /deep/ .el-select-dropdown {
      top: auto !important;
      left: auto !important;
      border: 1px solid rgb(60, 135, 216);
      &__empty,
      .el-select-dropdown__list {
        line-height: 20px;
        background: #091f2f;
        color: #abd6f0;
      }
      .el-select-dropdown__list {
        border-radius: 3px;
      }
      .el-select-dropdown__item {
        color: #abd6f0;
        font-weight: bold;
        font-size: 12px;
        &.hover {
          background-color: rgba(#154263, 68%);
          color: #04ffff !important;
        }
      }
      .popper__arrow {
        border-bottom-color: #3c87d8;
        &::after {
          border-bottom-color: #091f2f;
        }
      }
    }
    /deep/ .el-select .el-input .el-select__caret {
      color: #fff;
    }
    /deep/ .el-input__suffix-inner {
      border-color: none;
      .el-icon-circle-close:before {
        content: "\e79d" !important;
        font-size: 18px;
        color: #fff;
      }
    }
    /deep/ .teachManage-page {
      flex-direction: row-reverse;
      justify-content: flex-start;
      margin: 20px auto auto;
      .el-pagination__total {
        color: #abd6f0;
        font-size: 14px;
        font-weight: 400;
      }
      .el-pagination button {
        background-color: transparent;
        color: #abd6f0;
        padding: 0px;
        min-width: 25px;
        .el-icon {
          font-size: 14px;
        }
      }
      .el-pager li {
        color: #abd6f0;
        font-size: 16px;
        font-weight: 400;
        border-radius: 0;
        background-color: transparent;
        &.active {
          background: rgba(#64acd4, 10%);
          color: #05eeef;
          border-radius: 2px;
        }
      }
    }
    /deep/ .el-loading-mask {
      background-color: #091f2f;
    }
    .d-title {
      text-align: center;
      padding-bottom: 30px;
      font-size: 18px;
      font-weight: 300;
      color: #04ffff;
    }
    .f-button {
      text-align: center;
      position: absolute;
      bottom: 24px;
      left: calc(50% - 47px);
    }
    /deep/ .empty-data {
      .teachManage-page {
        display: none;
      }
    }
    .el-icon-error {
      position: absolute;
      right: 24px;
      top: 20px;
      font-size: 26px;
      color: #4071a5;
      cursor: pointer;
    }
  }
  /deep/ input::-webkit-input-placeholder {
    color: #abd6f0;
  }
  /deep/ input::-moz-input-placeholder {
    color: #abd6f0;
  }
  /deep/ input::-ms-input-placeholder {
    color: #abd6f0;
  }
  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(#051320, 46%);
    z-index: 1000;
    backdrop-filter: saturate(150%) blur(4px);
    -webkit-backdrop-filter: saturate(150%) blur(4px);
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
