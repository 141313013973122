<template>
  <div class="board-dialog">
    <el-dialog
      :title="optionsData.dialogTitle"
      :visible="visible"
      v-bind="optionsData"
      :width="optionsData.width || '600px'"
      :show-close="optionsData.showClose || false"
      :close-on-click-modal="optionsData.closeOnClickModal || false"
      :close-on-press-escape="optionsData.closeOnPressEscape || false"
      :modal-append-to-body="optionsData.modalAppendToBody || false"
      :left="optionsData.left ? true : false"
      @close="closeDialog"
    >
      <!-- 自定义内容 -->
      <slot name="dialogContent"></slot>
      <xm-form
        v-if="!hideForm"
        ref="form"
        :form-items="formItems"
        :form-options="newFormOptions"
        :form-data="queryParams"
        @submit.native.prevent
      >
        <el-form-item
          slot="formBtn"
          style="width: 100%; text-align: center; margin-top: 21px"
        >
          <el-button
            v-if="newFormOptions && !newFormOptions.hiddenDefaultFormBtn"
            class="btn-dialog"
            @click="closeDialog()"
            >{{ $t("el.common.cancel") }}</el-button
          >
          <el-button
            type="primary"
            @click="submitForm('form')"
            class="btn-dialog"
            v-if="newFormOptions && !newFormOptions.hiddenDefaultFormBtn"
            >{{ $t("el.common.confirm") }}</el-button
          >

          <slot name="formBtn" />
        </el-form-item>
      </xm-form>
    </el-dialog>
  </div>
</template>

<script>
import XmForm from "../../global/xm-form.vue";
export default {
  name: "BoardDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    optionsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否隐藏表单
    hideForm: {
      type: Boolean,
      default: false,
    },
    /**
     * 表单配置项
     */
    formItems: {
      type: Array,
      default: null,
    },
    /**
     * 表配置属性
     */
    formOptions: {
      type: Object,
      default: () => {},
    },
    /**
     * 表单参数
     */
    formQueryParams: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    XmForm,
  },
  data() {
    var queryParams = {};
    this.formItems &&
      this.formItems.forEach((item) => {
        queryParams[item.prop] = "";
      });
    return {
      queryParams, // 查询参数
    };
  },
  computed: {
    /**
     * 继承表单配置
     */
    newFormOptions() {
      return {
        inline: true,
        ...this.formOptions,
      };
    },
  },
  watch: {},
  mounted() {
    //   this.dialogVisible = this.visible;
    //   console.log("dialogVisible",this.dialogVisible);
  },
  methods: {
    // 关闭dialog
    closeDialog() {
      this.$refs["form"].resetForm();
      this.$emit("close-dialog");
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
        this.$emit("resetForm");
      });
    },
    // 提交
    submitForm(formName) {
      this.$emit(
        "submit-form",
        this.$refs[formName].$refs["form"],
        this.queryParams
      );
    },
    // getQueryParams() {
    //   return this.queryParams;
    // },
  },
};
</script>

<style lang="less" scoped>
.board-dialog {
  /deep/ .el-dialog__body {
    padding: 0 20px 0 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  /deep/ .el-dialog {
    display: inline-block;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    // background: rgba(17, 53, 78, 0.9);
    // box-shadow: 0px 0px 15px 5px #57c2ce inset;
    box-shadow: 0px 0px 10px 3px rgba(#04ffff, 0.8) inset;
    background: rgba(17, 53, 78, 0.8);

    // clip-path: polygon(
    //   0% 0%,
    //   100% 1%,
    //   100% 15px,
    //   100% 100%,
    //   calc(100% - 15px) 100%,
    //   15px 100%,
    //   0 calc(100% - 15px),
    //   0 15px
    // );
  }
  /deep/ .el-loading-mask {
    background-color: transparent;
  }
  /deep/ .el-dialog__header {
    padding: 26px 20px 23px;
  }
  /deep/ .el-dialog__title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #04ffff;
  }
  // /deep/ .el-dialog:before {
  //   content: "";
  //   position: absolute;
  //   height: 0;
  //   width: 0;
  //   bottom: -12px;
  //   left: -12px;
  //   border-width: 12px 12px 12px 12px;
  //   border-style: solid;
  //   border-color: transparent transparent red red;
  //   box-shadow: 0px 0px 15px 5px #57c2ce;
  //   transform: rotate(45deg);
  // }
  .btn-dialog {
    width: 79px;
    box-sizing: border-box;

    height: 26px;
    background: rgba(65, 142, 226, 0.2);
    border: 1px solid #3c87d8;
    opacity: 0.98;
    border-radius: 13px;
    text-align: center;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #04ffff;
    line-height: 26px;
    padding: 0 0;
    box-shadow: 1px 0px 12px 6px #418ee2 inset;
  }
}
.ml10 {
  margin-left: 10px;
}
.ml5 {
  margin-left: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
</style>
