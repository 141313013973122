<template>
  <div id="centerLeft1">
    <div
      class="d-flex flex-title"
      :class="$i18n.locale === 'zh_CN' ? 'pop-zh' : 'pop-en'"
    >
      <span class="fs-xl text"
        >{{ $t("el.board.Popularityisciplines")
        }}<hover-component
          :content="content"
          name="question-circle"
        ></hover-component>
      </span>
    </div>

    <dv-border-box-8
      v-loading="loading"
      element-loading-background="rgba(0,0,0,.2)"
      :dur="0"
      :color="['#3678C0']"
    >
      <div>
        <el-select
          :popper-append-to-body="false"
          v-model="gradeId"
          @change="getData"
          class="select-item"
        >
          <template>
            <el-option
              v-for="(option, i) in optionList"
              v-bind="option"
              :key="i"
            />
          </template>
        </el-select>
      </div>
      <div class="bg-color-black">
        <div v-if="!isEmpty" class="d-flex ai-center flex-column body-box">
          <CenterLeft1Chart :cdata="cdata" />
        </div>
        <empty-chart v-else></empty-chart>
      </div>
    </dv-border-box-8>
  </div>
</template>

<script>
import CenterLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
import emptyChart from "@/components/echart/empty";
import { handparams } from "../../../utils";
export default {
  props: {
    propertyGradeData: {
      type: Array,
      default: () => [],
    },
    etype: {
      type: Number,
      default: -1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    orgCode: {
      type: [String, Number],
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cdata: {},
      gradeId: "",
      optionList: [{ label: this.$t("el.courseList.allGrade"), value: "" }],
      loading: true,
      isEmpty: false,
      colorFormateData: [
        "#CC6475",
        "#F8B62B",
        "#58A371",
        "#EA5449",
        "#3F83C5",
        "#09D5DC",
        "#6C6FBF",
        "#F29C9F",
        "#B3D465",
        "#13B5B1",
        "#C786F9",
      ],
    };
  },
  components: {
    CenterLeft1Chart,
    emptyChart,
  },
  watch: {
    propertyGradeData(val) {
      this.optionList = [
        { label: this.$t("el.courseList.allGrade"), value: "" },
      ];
      let data = [];
      if (val.length > 0) {
        data = this.propertyGradeData;
        data.map((item) => {
          this.$set(item, "label", item.name);
          this.$set(item, "value", item.id);
        });
      }
      this.optionList.push(...data);
    },
    sumDate(val) {
      if (val) {
        this.getData();
      }
    },
  },
  computed: {
    // hover内容
    content() {
      let text;
      switch (this.etype) {
        case 1:
        case 4:
          text = `<p>${this.$t("el.board.PlatformPopularity")}</p><p>${this.$t(
            "el.board.PlatformPopularityValue"
          )}</p>`;
          break;
        case 2:
          text = `<p>${this.$t("el.board.AreaPopularity")}</p><p>${this.$t(
            "el.board.AreaPopularityValue"
          )}</p>`;
          break;
        case 3:
          text = `<p>${this.$t("el.board.SchoolPopularity")}</p><p>${this.$t(
            "el.board.SchoolPopularityValue"
          )}</p>`;
          break;
        default:
          text = "";
      }
      return text;
    },
    sumDate() {
      return this.startDate + this.endDate;
    },
    trueCode() {
      if (this.etype === 2) {
        return this.areaCode;
      } else if (this.etype === 3) {
        return this.schoolCode;
      } else if (this.etype === 4) {
        return this.orgCode;
      } else {
        return "";
      }
    },
  },
  methods: {
    // 获取学科热度数据
    async getData() {
      this.loading = true;
      let params = {
        code: this.trueCode,
        startDate: this.startDate,
        endDate: this.endDate,
        gradeId: this.gradeId,
        kanbanType: this.etype,
      };
      await this.$store
        .dispatch("getSubjectHeat", handparams(params))
        .then((res) => {
          if (res.data.length === 0) {
            this.isEmpty = true;
          } else {
            let cdata = res.data;
            cdata.forEach((item, index) => {
              this.$set(item, "value", item.heat);
              this.$set(item, "name", item.subjectName);
              this.$set(item, "itemStyle", {
                color: this.colorFormateData[index],
              });
              delete item.heat;
              delete item.subjectId;
              delete item.subjectName;
            });
            this.cdata = { data: cdata };
            this.isEmpty = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.isEmpty = true;
          this.loading = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 305px;

#centerLeft1 {
  padding: 5px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  .flex-title {
    width: max-content;
    max-width: 75%;
    z-index: 999;
    position: absolute;
    top: -2%;
    left: 50%;
    transform: translateX(-50%);
    background: #162a5f;
    // height: 25px;
    line-height: 25px;
    padding: 0 20px;
    .text {
      font-size: 16px;
      color: #04ffff;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      word-break: break-all;
      text-align: center;
    }
    ::v-deep .icon-component-tooltip {
      right: -190px;
    }
  }
  .pop-zh {
    ::v-deep .icon-component-tooltip {
      right: -190px;
    }
  }
  .pop-en {
    ::v-deep .icon-component-tooltip {
      right: -90px;
    }
  }
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    .item-box {
      & > div {
        padding-right: 5px;
      }
      font-size: 14px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      .dv-digital-flop {
        width: 120px;
        height: 30px;
      }
      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
}
.bottom-data {
  .item-box {
    & > div {
      padding-right: 5px;
    }
    font-size: 14px;
    float: right;
    position: relative;
    width: 50%;
    color: #d3d6dd;
    .dv-digital-flop {
      width: 120px;
      height: 30px;
    }
    .coin {
      position: relative;
      top: 6px;
      font-size: 20px;
      color: #ffc107;
    }
    .colorYellow {
      color: yellowgreen;
    }
    p {
      text-align: center;
    }
  }
}
</style>
<style lang="less" scoped>
#centerLeft1 {
  /deep/ .el-select-dropdown {
    top: auto !important;
    left: auto !important;
    border: 1px solid rgb(60, 135, 216);
    &__empty,
    .el-select-dropdown__list {
      line-height: 20px;
      background: #091f2f;
      color: #abd6f0;
      border-radius: 3px;
    }
    .el-select-dropdown__item {
      color: #abd6f0;
      font-weight: bold;
      font-size: 12px;
      &.hover {
        background-color: rgba(#154263, 68%);
        color: #04ffff !important;
      }
    }
    .el-select-dropdown__item.selected {
      background-color: rgba(#154263, 68%);
      color: #04ffff !important;
    }
    .popper__arrow {
      border-bottom-color: #3c87d8;
      &::after {
        border-bottom-color: #091f2f;
      }
    }
  }
  /deep/ .el-select .el-input .el-select__caret {
    color: #fff;
  }
  /deep/ .el-select {
    margin-left: 28px;
    margin-top: 25px;
    > .el-input {
      width: 90px;
      font-size: 14px;
      position: relative;
    }
  }
  /deep/ .el-select-dropdown__wrap {
    max-height: 230px;
  }
  /deep/ .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 16px !important;
  }
  /deep/ .el-input__inner {
    -webkit-appearance: none;
    background-color: transparent;
    background-image: none;
    border-radius: 4px;
    border: 0px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-block;
    font-size: inherit;
    height: 16px !important;
    line-height: 16px !important;
    outline: 0;
    padding: 0 0;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  /deep/ .empty-chart {
    padding-top: 53px;
  }
}
</style>
