var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dayClassDuration",attrs:{"id":"dayClassDuration"}},[_c('div',{staticClass:"d-flex flex-title",class:_vm.$i18n.locale === 'zh_CN' ? 'pop-zh' : 'pop-en'},[_c('span',{staticClass:"fs-xl text"},[_vm._v(_vm._s(_vm.$t("el.board.DurationDayStudent"))+" (min)"),_c('hover-component',{staticStyle:{"text-align":"left"},attrs:{"content":_vm.content[_vm.etype - 1],"name":"question-circle"}})],1)]),_c('dv-border-box-8',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dv-border",attrs:{"element-loading-background":"rgba(0,0,0,.2)","color":['#3678C0'],"dur":0}},[_c('div',{staticClass:"flex",staticStyle:{"padding-top":"25px"}},[_c('xm-form',{ref:"form",staticClass:"class-form flex",attrs:{"form-items":_vm.formItems,"form-options":_vm.newFormOptions,"form-data":_vm.queryParams},nativeOn:{"submit":function($event){$event.preventDefault();}}}),_c('div',{staticClass:"day-class-more",on:{"click":_vm.showMoreDialog}},[_vm._v(" "+_vm._s(_vm.$t("el.board.More"))+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showEmpty && !_vm.loading),expression:"!showEmpty && !loading"}],class:[_vm.$i18n.locale === 'zh_CN' ? '' : 'content-en']},[_c('div',{staticStyle:{"min-height":"190px"}},[_c('day-class-duration',{staticClass:"dv-cap-chart",attrs:{"id":"dayClassDurationContent","classDurationList":_vm.dayClassTimeList}})],1),_c('div',{staticClass:"flex flex-b btn-bottom"},[_c('div',{staticClass:"btn-day-class"},[_vm._v(" "+_vm._s(_vm.$t("el.board.NumberCoursesPerson"))+" "+_vm._s(_vm.$t("el.common.colon"))),_c('span',[_vm._v(_vm._s(_vm.durationTimeTotal.courseNum))])]),_c('div',{staticClass:"btn-day-class-bg"}),_c('div',{class:[
            _vm.$i18n.locale === 'zh_CN' ? 'btn-day-class' : 'btn-day-class-2',
          ]},[_vm._v(" "+_vm._s(_vm.$t("el.board.PerCapitaDuration"))+_vm._s(_vm.$t("el.common.colon"))),_c('span',[_vm._v(_vm._s(_vm.toFixed5(_vm.durationTimeTotal.time, 1)))])]),_c('div',{staticClass:"btn-day-class-bg2"})])]),_c('empty-chart',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmpty && !_vm.loading),expression:"showEmpty && !loading"}]})],1),(_vm.showDialog)?_c('dialog-component',{staticClass:"day-class-table",on:{"closeDialog":function($event){_vm.showDialog = false}}},[_c('template',{slot:"header"},[_c('p',{staticClass:"d-title"},[_vm._v(_vm._s(_vm.$t("el.board.DurationDayStudent")))])]),_c('template',{slot:"xm-table"},[_c('xm-table',{ref:"xmTable",class:_vm.computedClassName,attrs:{"fatch-method":_vm.fatchMethod,"form-items":_vm.formItemsDialog,"columns":_vm.columns,"formOptions":{
          hiddenDefaultFormBtn: true,
        },"tableOptions":{
          stripe: true,
          border: false,
        },"showPagination":_vm.isShowPagination,"sreenEmpty":true,"defaultLimit":5,"autoLoad":false},on:{"changeQueryParams":_vm.changeQueryParams}},[_c('template',{slot:"formBtn"},[_c('el-button',{staticClass:"teachManage-tool-btnicon",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.query()}}},[_vm._v(_vm._s(_vm.$t("el.common.search")))])],1)],2)],1),_c('template',{slot:"footer"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowPagination),expression:"isShowPagination"}],staticClass:"f-button"},[_c('el-button',{attrs:{"loading":_vm.exportLoading,"type":"primary","size":"small"},on:{"click":_vm.exportData}},[_vm._v(_vm._s(_vm.$t("el.common.export"))+" ")])],1)])],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }