<template>
  <div class="chart-content">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
// import { usageData } from "@/api/screen";
// import moment from "moment";
export default {
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  components: {
    Chart,
  },
};
</script>
<style lang="less" scoped>
.chart-content {
  width: 100%;
}
</style>
