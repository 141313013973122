<template>
  <div class="empty-chart">
    <img src="@/assets/images/no-data.png" alt="" style="width: 200px" />
    <p>{{ $t("el.common.noData") }}</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.empty-chart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 71px;
  p {
    color: #04ffff;
    font-size: 13px;
    font-weight: 400;
  }
}
</style>
