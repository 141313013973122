<template>
  <div id="bottomLeft" class="average-use-time">
    <!-- <div class="bg-color-black"> -->
    <div class="d-flex flex-title">
      <span class="fs-xl text"
        >{{ $t("el.board.AverageDurationUse") }} (min)<hover-component
          style="text-align: left"
          :content="content[etype - 1]"
          name="question-circle"
        ></hover-component
      ></span>
    </div>
    <dv-border-box-8
      v-loading="loading"
      element-loading-background="rgba(0,0,0,.2)"
      :color="['#3678C0']"
      :dur="0"
    >
      <div v-if="!showEmpty && !loading" class="bg-color-black">
        <AverageUseTime />
      </div>
      <empty-chart v-if="showEmpty && !loading"></empty-chart>
    </dv-border-box-8>
  </div>
  <!-- </div> -->
</template>

<script>
import hoverComponent from "@/components/echart/hover/index";
import AverageUseTime from "@/components/echart/histogram/averageUseTime";
import emptyChart from "@/components/echart/empty";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      content: [
        `${this.$t("el.board.TrendChartTimeRange")}</br>${this.$t(
          "el.board.PerCapitaLessonPreparationTime"
        )}</br>${this.$t("el.board.PerCapitaTeachingHours")}`,
        `${this.$t("el.board.TrendChartTimeRange")}</br>${this.$t(
          "el.board.PerCapitaLessonPreparationDuration"
        )}</br>${this.$t("el.board.PerCapitaTeachingDuration")}`,
        `${this.$t("el.board.TrendChartTimeRange")}</br>${this.$t(
          "el.board.PerCapitaLessonDuration"
        )}</br>${this.$t("el.board.PerCapitaTeachingDurationDesc")}`,
        `${this.$t("el.board.TrendChartTimeRange")}</br>${this.$t(
          "el.board.PerCapitaLessonPreparationTime"
        )}</br>${this.$t("el.board.PerCapitaTeachingHours")}`,
      ],
      showEmpty: false,
    };
  },
  watch: {
    "averageTimedata.category": {
      handler(val, old) {
        if (val.length === 0) {
          this.showEmpty = true;
        } else {
          this.showEmpty = false;
        }
      },
    },
  },
  props: {
    etype: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ...mapGetters(["averageTimedata"]),
    AverageUseTime,
    hoverComponent,
    emptyChart,
  },
};
</script>

<style lang="scss" scoped>
$box-height: 305px;
$box-width: 100%;
#bottomLeft {
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
  padding: 5px;
  .bg-color-black {
    // padding: 5px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
    // padding-top: 30px;
  }
  .flex-title {
    width: max-content;
    z-index: 999;
    position: absolute;
    top: -2%;
    transform: translateX(-50%);
    left: 50%;
    background: #162a5f;
    // height: 25px;
    line-height: 25px;
    text-align: center;
    padding: 0 20px;
    .text {
      font-size: 16px;
      color: #04ffff;
      font-weight: 500;
      word-break: break-all;
    }
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
<style lang="less" scoped>
.average-use-time {
  /deep/ .icon-component-tooltip {
    position: absolute;
    right: 0;
    font-size: 12px;
    line-height: 20px;
    right: -180px;
    padding: 18px;
    color: #04fbfb;
    font-family: PingFang SC;
    font-weight: 300;
  }
  /deep/ .icon-component-question-icon {
    color: #99c0d8;
    margin-left: 8px;
    // vertical-align: bottom;
    width: 16px;
    height: 16px;
    // margin-bottom: 5px;
  }
}
</style>
