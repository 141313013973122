<template>
  <div id="index" ref="appRef" class="statistical-board">
    <div class="bg">
      <!-- <dv-loading v-if="loading">Loading...</dv-loading> -->
      <div
        v-loading="allLoading"
        element-loading-background="rgba(0,0,0,0.4)"
        class="host-body"
      >
        <div class="statistical-board-header">
          <div class="statistical-board-header-top flex flex-m">
            <el-image class="logo" :src="haiLiangLogo"></el-image>
            <div class="flex flex-m title-content">
              <span>{{ boardTitle }}</span>
              <!-- TODO: 待定 -->
              <el-image
                v-if="Number(userInfo.tenantType) !== 2"
                @click="changeLookBoard"
                class="change-logo"
                :src="iconChangeBoard"
              ></el-image>
            </div>
            <div
              class="time-change flex flex-m"
              :style="$i18n.locale === 'zh_CN' ? '' : 'margin-right:34px'"
            >
              <span>
                {{ $t("el.board.TimeRange") + $t("el.common.colon") }}
              </span>
              <div
                style="margin-left: 11px; cursor: pointer"
                :class="choseSeven ? 'btn-chose' : 'btn-no-chose'"
                @click="changeDateRange(0)"
              >
                {{ $t("el.board.Nearly7days") }}
              </div>
              <div
                style="margin-left: 13px; cursor: pointer"
                :class="choseThirty ? 'btn-chose' : 'btn-no-chose'"
                @click="changeDateRange(1)"
              >
                {{ $t("el.board.Nearly30days") }}
              </div>
              <div>
                <el-date-picker
                  v-model="searchDate"
                  type="daterange"
                  class="date-range"
                  size="small"
                  :clearable="false"
                  :range-separator="$t('el.common.to')"
                  :start-placeholder="$t('el.common.startDate')"
                  :end-placeholder="$t('el.common.startDate')"
                  @change="datePickerChange"
                  :picker-options="pickerOptions"
                  popper-class="popper-class-date"
                  :append-to-body="false"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="h-line"></div>
          <div class="statistical-board-header-bottom">
            <p>{{ $t("el.board.lastModifyTime") }} {{ lastModifyTime }}</p>
          </div>
        </div>
        <!-- 第二行 -->
        <!-- <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-before"></span>
              <span class="text">数据分析1</span>
            </div>
            <div class="react-right ml-3">
              <span class="text">数据分析2</span>
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b">vue-big-screen</span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
            </div>
          </div>
        </div> -->

        <div class="body-box">
          <div class="content-box">
            <div>
              <!-- 左侧 -->
              <usage-statistics
                :startDate="startDate"
                :endDate="endDate"
                :schoolCode="schoolCode"
                :areaCode="areaCode"
                :orgCode="orgCode"
                :etype="boardType"
              />
              <average-use-time
                :loading="averageLoading"
                :schoolCode="schoolCode"
                :etype="boardType"
              />
              <time-ranking
                :startDate="startDate"
                :endDate="endDate"
                :schoolCode="schoolCode"
                :areaCode="areaCode"
                :orgCode="orgCode"
                :etype="boardType"
              />
              <!-- </dv-border-box-12> -->
            </div>
            <!-- 中间 -->
            <div>
              <center
                :startDate="startDate"
                :endDate="endDate"
                @getCreateTime="getCreateTime"
                :schoolCode="schoolCode"
                :areaCode="areaCode"
                :orgCode="orgCode"
                :etype="boardType"
              />
              <map-statistics
                :startDate="startDate"
                :schoolCode="schoolCode"
                :areaCode="mapCode"
                :orgCode="orgCode"
                :areaName="areaName"
                :etype="boardType"
              />
              <!-- <course-heat-ranking /> -->
              <participation-data
                :startDate="startDate"
                :endDate="endDate"
                :schoolCode="schoolCode"
                :areaCode="areaCode"
                :orgCode="orgCode"
                :etype="boardType"
              />
            </div>
            <!-- 右侧 -->
            <div>
              <!-- <dv-border-box-13> -->
              <discipline-heat-distribution
                :startDate="startDate"
                :endDate="endDate"
                :schoolCode="schoolCode"
                :areaCode="areaCode"
                :orgCode="orgCode"
                :etype="boardType"
                :propertyGradeData="propertyGradeData"
              />
              <!-- <participation-data /> -->
              <course-heat-ranking
                :startDate="startDate"
                :endDate="endDate"
                :schoolCode="schoolCode"
                :areaCode="areaCode"
                :orgCode="orgCode"
                :etype="boardType"
                :propertyGradeData="propertyGradeData"
                :propertySubjectData="propertySubjectData"
              />
              <day-class-duration
                ref="dayClassDuration"
                :startDate="startDate"
                :endDate="endDate"
                :schoolCode="schoolCode"
                :areaCode="areaCode"
                :orgCode="orgCode"
                :etype="boardType"
                :loading="dayClassLoading"
                @onChangeData="onDayClassDuration"
              />
              <!-- </dv-border-box-13> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <change-board
      ref="changeBoard"
      :showChangeBoard="showChangeBoard"
      :optionsData="optionsData"
      @close-dialog="closeChangeBoard"
      :schoolCode="schoolCode"
      :areaCode="areaCode"
      :etype="boardType"
      :loadBoardOptionData="loadBoardOptionData"
      :realTenentType="Number(userInfo.tenantType)"
    ></change-board>
    <board-dialog
      ref="permissionBoardDialog"
      class="permission-dialog"
      :visible="showPermissionVisible"
      :optionsData="{ width: '400px' }"
      :formOptions="{ hiddenDefaultFormBtn: true }"
    >
      <template slot="dialogContent">
        <div style="color: #04ffff; font-size: 14px">
          抱歉，您暂无当前看板权限，请关闭页面重新尝试~
        </div>
      </template>
    </board-dialog>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import DisciplineHeatDistribution from "./disciplineHeatDistribution.vue"; // 学科热度分布
import MapStatistics from "./mapStatistics.vue"; // 地图
import CourseHeatRanking from "./courseHeatRanking1.vue"; // 课程热度排行
import center from "./center"; // 中间文字部分
import UsageStatistics from "./usageStatistics.vue"; // 使用情况统计
import AverageUseTime from "./averageUseTime.vue"; // 人均使用时长
import TimeRanking from "./timeRanking.vue"; // 备授课时长排行
import DayClassDuration from "./dayClassDuration.vue"; // 学生每天上课时长
import ParticipationData from "./participationData"; // 使用情况
import { getAreaStaticData, getSchoolStaticData } from "@/api/screen/index";
import { startOfDay, endOfDay, subDays } from "date-fns";
import ChangeBoard from "../../../components/biz-components/changeBoard/index.vue";
import BoardDialog from "../../../components/biz-components/BoardDialog/index.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "statisticalBoard",
  mixins: [drawMixin],
  components: {
    DisciplineHeatDistribution,
    MapStatistics,
    CourseHeatRanking,
    center,
    DayClassDuration,
    UsageStatistics,
    AverageUseTime,
    TimeRanking,
    ParticipationData,
    ChangeBoard,
    BoardDialog,
  },
  data() {
    return {
      allLoading: false,
      haiLiangLogo: require("../../../assets/images/icon/icon-hailiang-logo-sz.svg"), // logo的svg版本
      iconChangeBoard: require("../../../assets/images/icon/icon-change-board.png"),
      searchDate: [],
      choseThirty: false,
      choseSeven: true,
      startDate: "",
      endDate: "",
      showChangeBoard: false,
      optionsData: {},
      schoolCode: "",
      schoolName: "",
      areaCode: "",
      areaName: "",
      orgCode: "",
      orgName: "",
      provinceCode: "",
      cityCode: "",
      mapCode: "",
      provinceName: "",
      cityName: "",
      lastModifyTime: "",
      dayClassLoading: true,
      averageLoading: true,
      showPermissionVisible: false,
      pickerOptions: {
        disabledDate: (time) => {
          return time && time > moment().subtract(0, "days");
        },
      },
      boardType: null,
      loadBoardOptionData: false,
    };
  },
  async created() {
    console.log("this.userInfo====", this.userInfo);
    this.boardType = [1, 3, 2, 4][Number(this.userInfo.tenantType) - 1];
    this.init();
  },
  computed: {
    ...mapState({
      areaSchoolList: (state) => state.board.areaSchoolList,
      userInfo: (state) => state.user,
    }),
    ...mapGetters([
      // "boardOptionsList",
      // "boardAddressList",
      // "boardAreaList",
      "lookBoardInfo",
      "propertyGradeData",
      "propertySubjectData",
    ]),
    boardTitle() {
      console.log("this.boardType+++++", this.boardType);
      if (this.boardType === 3) {
        return this.schoolName + this.$t("el.board.PlatformDataPanel");
      } else if (this.boardType === 2) {
        return this.areaName + this.$t("el.board.PlatformDataPanel");
      } else if (this.boardType === 4) {
        return this.orgName + this.$t("el.board.PlatformDataPanel");
      } else if (this.boardType === 1) {
        return this.$t("el.board.DataPanelHailiang");
      }
      return null;
    },
  },
  watch: {
    searchDate: {
      handler(val, old) {
        if (val !== null && val !== undefined) {
          if (val.length === 2) {
            this.startDate = this.$dateFormat(val[0], "yyyy-MM-dd HH:mm:ss");
            this.endDate = this.$dateFormat(val[1], "yyyy-MM-dd 23:59:59");
            this.getAllTable();
          }
        }
      },
      // deep: true,
    },
    lookBoardInfo: {
      handler(val, old) {
        if (val !== null && val !== undefined) {
          this.toChangeLookBoard(val); // 切换看板
        }
      },
      deep: true,
      // immediate: true,
    },
  },
  methods: {
    ...mapActions([
      "getGradePropertyData",
      "getSubjectPropertyData",
      "getBoardSelect",
      // "getBoardOptions",
      "getBoardAllTableRespectively",
      "getDayClassTimes",
      // "getBoardAllTable",
      "getAvgTimes",
      "getDurationTimeTotal",
      "getAreaSchoolList", // 获取学校列表
    ]),
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    checkPermission(type) {
      let element = this.boardOptionsList.some((element) => {
        if (~~element.value === type) {
          return true;
        }
      });
      console.log("element.kanbanType", element);
      if (!element) {
        console.log("不存在权限");
        this.showPermissionVisible = true;
        return false;
      } else {
        return true;
      }
    },
    async getAreaList(type) {
      await this.getBoardOptions();
      // console.log("getAreaList",);
      if (type === 3) {
        if (!this.checkPermission(type)) {
          return;
        }
        if (this.boardAddressList.length > 0) {
          let item = this.getFirstItem(this.boardAddressList);
          console.log("item===", item);
          this.areaCode = item.addressCode;
          this.areaName = item.addressName;
          await this.getAreaSchoolList(this.areaCode);
          this.schoolCode =
            this.areaSchoolList[0] && this.areaSchoolList[0].schoolCode;
          this.schoolName =
            this.areaSchoolList[0] && this.areaSchoolList[0].schoolName;
        }
      } else if (type === 2) {
        if (!this.checkPermission(type)) {
          return;
        }
        if (this.boardAreaList.length > 0) {
          let item = this.getFirstItem(this.boardAreaList);
          this.areaCode = item.addressCode;
          this.areaName = item.addressName;
        }
      } else if (type === 1) {
        if (!this.checkPermission(type)) {
          return;
        }
      }
    },
    // 获取树形结构的第一个item
    getFirstItem(list) {
      let item;
      list.some((element) => {
        if (element.children && element.children.length > 0) {
          item = this.getFirstItem(element.children);
          return true;
        } else {
          console.log("element", element);
          item = element;

          return true;
        }
      });
      return item;
    },

    async getAuthSchoolList() {
      await this.$store.dispatch("getManageSchoolList").then((res) => {
        let schoolOptions = res.data;
        if (schoolOptions.length > 0) {
          this.schoolCode = schoolOptions[0].schoolCode;
          console.log("schoolCode", this.schoolCode);
          this.schoolName = schoolOptions[0].schoolName;
        }
      });
    },
    // 获取图数据
    async getAllTable() {
      // console.log("startDate", this.startDate);
      // console.log("endDate", this.endDate);
      let params;
      const code = ["", this.areaCode, this.schoolCode, this.orgCode][
        Number(this.boardType) - 1
      ];
      params = {
        code,
        startDate: this.startDate,
        endDate: this.endDate,
        kanbanType: ~~this.boardType,
      };
      // this.getAvgTimes(params);
      this.$refs["dayClassDuration"].queryParams = {
        type:
          this.boardType === 1 || this.boardType === 4
            ? 3
            : this.boardType === 3
            ? 1
            : null,
        sort: "",
      };
      this.getDurationTimeTotal(this.handparams(params));
      this.getAverageTimes(this.handparams(params));
      // this.getBoardAllTableRespectively(this.handparams(params));
      // this.getBoardAllTable(params);
    },
    async getAverageTimes(params) {
      this.averageLoading = true;
      await this.getAvgTimes(params);
      this.averageLoading = false;
    },
    // 切换学生人均使用时长数据
    async onDayClassDuration(params) {
      let data = {
        code:
          ~~this.boardType === 2
            ? this.areaCode
            : ~~this.boardType === 3
            ? this.schoolCode
            : ~~this.boardType === 4
            ? this.orgCode
            : "",
        startDate: this.startDate,
        endDate: this.endDate,
        kanbanType: this.boardType,
        limit: 5,
        offset: 0,
        ...params,
      };
      this.dayClassLoading = true;
      await this.getDayClassTimes(this.handparams(data));
      this.dayClassLoading = false;
    },
    // 关闭切换看板弹窗
    closeChangeBoard() {
      this.showChangeBoard = false;
    },
    // 日期选择 改变监听
    datePickerChange() {
      this.choseThirty = false;
      this.choseSeven = false;
    },
    // 修改时间选择
    changeDateRange(type) {
      if (type) {
        this.choseThirty = true;
        this.choseSeven = false;
        this.startDate = this.endDate = "";
        this.$nextTick(() => {
          this.searchDate = [
            subDays(startOfDay(new Date()), 30),
            endOfDay(subDays(new Date(), 1)),
          ];
        });
      } else {
        this.choseThirty = false;
        this.choseSeven = true;
        this.startDate = this.endDate = "";
        this.$nextTick(() => {
          this.searchDate = [
            subDays(startOfDay(new Date()), 7),
            endOfDay(subDays(new Date(), 1)),
          ];
        });
      }
    },
    // 去切换看板
    toChangeLookBoard(val) {
      // console.log("val===", val);
      // if (val.kanbanType === 1) {
      //   this.$router.replace({
      //     name: "statisticalBoard",
      //     query: {
      //       staticType: val.kanbanType,
      //     },
      //   });
      // } else if (val.kanbanType === 2) {
      //   this.$router.replace({
      //     name: "statisticalBoard",
      //     query: {
      //       staticType: val.kanbanType,
      //       areaCode: val.areaCode,
      //       areaName: val.areaName,
      //     },
      //   });
      // } else if (val.kanbanType === 3) {
      //   this.$router.replace({
      //     name: "statisticalBoard",
      //     query: {
      //       staticType: val.kanbanType,
      //       areaCode: val.schoolAreaCode,
      //       schoolCode: val.schoolCode,
      //       schoolName: val.schoolName,
      //     },
      //   });
      // }
      console.log(val, 78787878);
      this.boardType = val.kanbanType;
      if (val.kanbanType === 2) {
        this.areaCode = val.areaCodeList || this.userInfo.tenantId;
        this.areaName = val.areaName || this.userInfo.tenantName;
      } else if (val.kanbanType === 3) {
        this.schoolCode = val.schoolCode;
        this.schoolName = val.schoolName;
      } else if (val.kanbanType === 4) {
        this.orgCode = this.userInfo.tenantId;
        this.orgName = this.userInfo.tenantName;
      }
      this.closeChangeBoard();
      // this.init(); // 初始化数据
      this.changeDateRange(0);
    },
    // 点击切换看板
    async changeLookBoard() {
      this.allLoading = true;
      console.log(
        " this.boardOptionsList====",
        this.boardOptionsList,
        this.boardAddressList,
        this.boardAreaList
      );
      this.allLoading = false;
      this.showChangeBoard = true;
    },
    // 获取年级/学科下拉框数据
    async initSelctOptions() {
      // await this.getGradePropertyData();
      // await this.getSubjectPropertyData();
      return Promise.all([
        this.getGradePropertyData(),
        this.getSubjectPropertyData(),
      ])
        .then(() => {
          console.log("Promise.all 2");
        })
        .catch(() => {
          console.log("err 3233");
        });
      // console.log("4444444");
    },
    async init1() {
      // 两个接口同时调用，通过 await等待接口执行完返回结果再执行下面代码
      await this.initSelctOptions();
      if (this.boardType === 3) {
        if (this.$route.query.schoolCode && this.$route.query.areaCode) {
          this.schoolCode = this.$route.query.schoolCode;
          this.schoolName = this.$route.query.schoolName;
          this.areaCode = this.$route.query.areaCode;
          this.areaName = this.$route.query.areaName;
          await this.getBoardOptions();
          if (!this.checkPermission(this.boardType)) {
            return;
          }
        } else {
          // await this.getAuthSchoolList();
          await this.getAreaList(this.boardType);
        }
        this.getProvinceCode(
          this.boardAddressList,
          this.areaCode,
          this.areaName
        );
      } else if (this.boardType === 2) {
        if (this.$route.query.areaCode) {
          this.areaCode = this.$route.query.areaCode;
          this.areaName = this.$route.query.areaName;
          await this.getBoardOptions();
          if (!this.checkPermission(this.boardType)) {
            return;
          }
        } else {
          // await this.getAuthSchoolList();
          await this.getAreaList(this.boardType);
        }
        this.getProvinceCode(this.boardAreaList, this.areaCode, this.areaName);
      } else {
        await this.getBoardOptions();
        if (!this.checkPermission(this.boardType)) {
          return;
        }
      }
      await this.changeDateRange(0);
    },
    // 初始化
    async init() {
      // 获取年级/科目下拉框接口同时调用，通过 await等待接口执行完返回结果再执行下面代码
      await this.initSelctOptions();
      console.log("this.boardType====", this.boardType);
      if (this.boardType === 3) {
        this.schoolCode = this.userInfo.tenantId;
        this.schoolName = this.userInfo.tenantName;
      } else if (this.boardType === 2) {
        this.areaCode = this.userInfo.tenantId;
        this.areaName = this.userInfo.tenantName;
        this.boardOptionsList = [
          {
            name: "区域看板",
            value: 2,
          },
          {
            name: "学校看板",
            value: 3,
          },
        ];
        await this.getSchoolOptions({ areaId: this.areaCode });
      } else if (this.boardType === 4) {
        this.orgCode = this.userInfo.tenantId;
        this.orgName = this.userInfo.tenantName;
        this.boardOptionsList = [
          {
            name: "机构看板",
            value: 4,
          },
          {
            name: "区域看板",
            value: 2,
          },
          {
            name: "学校看板",
            value: 3,
          },
        ];
        await this.getAreaOptions();
        await this.getSchoolOptions({ orgId: this.orgCode });
      } else {
        this.boardOptionsList = [
          {
            name: "平台看板",
            value: 1,
          },
          {
            name: "区域看板",
            value: 2,
          },
          {
            name: "学校看板",
            value: 3,
          },
        ];
        await this.getAreaOptions();
        await this.getSchoolOptions();
      }
      this.optionsData = {
        dialogTitle: this.$t("el.board.selectPanel"),
        width: "fit-content",
        boardOptionsList: this.boardOptionsList, // 看板radio 选项
        boardAddressList: this.boardAddressList, // 看板学校下拉数据
        boardAreaList: this.boardAreaList, // 看板区域下拉数据
      };
      this.loadBoardOptionData = true;
      await this.changeDateRange(0);
    },
    // 获取某区域下的所有学校数据
    async getSchoolOptions(params) {
      try {
        const { data } = await getSchoolStaticData(params);
        this.boardAddressList = data || [];
      } catch (error) {
        console.log(error);
      }
    },
    // 获取某平台/机构下所有的区域数据
    async getAreaOptions() {
      try {
        const { data } = await getAreaStaticData(
          this.boardType === 4 ? this.orgCode : ""
        );
        console.log("data4444====", data);
        this.boardAreaList = data || [];
      } catch (error) {
        console.log(error);
      }
    },
    getProvinceCode(list, areaCode, areaName) {
      if (areaCode) {
        let result = this.treeFindPath(
          "addressCode",
          list,
          (node) => node.addressCode === areaCode
        );
        let addressNameList = this.treeFindPath(
          "addressName",
          list,
          (node) => node.addressName === areaName
        );
        // console.log("getProvinceCode:", result);
        if (result && result.length > 2) {
          this.provinceCode = result[result.length - 3];
          this.cityCode = result[result.length - 2];
          this.provinceName = addressNameList[addressNameList.length - 3];
          this.cityName = addressNameList[addressNameList.length - 2];
          // console.log("cityName", this.cityName);
        } else {
          this.provinceCode = "";
          this.cityCode = "";
          this.provinceName = "";
          this.cityName = "";
        }
        let directlyCity = [
          "110000",
          "120000",
          "310000",
          "500000",
          "810000",
          "820000",
          "710000",
        ];
        if (directlyCity.indexOf(this.provinceCode) > -1) {
          this.mapCode = this.provinceCode + "_full";
        } else {
          this.mapCode = this.cityCode + "_full";
        }
      } else {
        this.mapCode = this.cityCode + "_full";
      }
    },
    // 获取树状结构 path
    treeFindPath(key, tree, func, path = []) {
      if (!tree) return [];
      for (const data of tree) {
        path.push(data[key]);
        if (func(data)) return path;
        if (data.children) {
          const findChildren = this.treeFindPath(
            key,
            data.children,
            func,
            path
          );
          if (findChildren.length) return findChildren;
        }
        path.pop();
      }
      return [];
    },
    getCreateTime(time) {
      this.lastModifyTime = time;
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/charts.less";
.statistical-board {
  color: #fff;
  #dv-full-screen-container {
    background: #020308;
  }
  .statistical-board-header {
    &-top {
      height: 54px;
      background: rgba(10, 46, 76, 0.6);
      position: relative;
      z-index: 1000;
      .logo {
        width: 155px;
        height: 36px;
        margin-left: 134px;
      }
      .title-content {
        position: absolute;
        width: fit-content;
        height: 54px;
        line-height: 54px;
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        transform: translateX(-50%);
        top: 0;
        left: 50%;

        .change-logo {
          width: 20px;
          height: 20px;
          margin-left: 14px;
          cursor: pointer;
        }
      }
      .time-change {
        font-size: 14px;
        font-weight: 400;
        color: #99c0d8;
        line-height: 54px;
        margin-left: auto;
        margin-right: 134px;
        .btn-chose {
          width: fit-content;
          padding-left: 20px;
          padding-right: 20px;
          box-sizing: border-box;
          height: 26px;
          background: rgba(65, 142, 226, 0.2);
          border: 1px solid #3c87d8;
          opacity: 0.98;
          border-radius: 13px;
          text-align: center;
          font-size: 13px;
          font-weight: 500;
          color: #04ffff;
          line-height: 26px;
          box-shadow: 1px 0px 12px 6px #418ee2 inset;
        }
        .btn-no-chose {
          width: fit-content;
          padding-left: 20px;
          padding-right: 20px;
          box-sizing: border-box;
          height: 26px;
          background: rgba(65, 142, 226, 0.2);
          border: 1px solid #3c87d8;
          opacity: 0.98;
          border-radius: 13px;
          font-size: 13px;
          font-weight: 500;
          text-align: center;
          color: #ffffff;
          line-height: 26px;
        }
      }
      .date-range {
        margin-left: 20px;
        width: 235px;
        height: 32px;
        border: 1px solid #3678c0;
      }
      /deep/ .el-input__inner {
        -webkit-appearance: none;
        background-color: transparent;
        background-image: none;
        border-radius: 4px;
        /* border: 1px solid #DCDFE6; */
        /* -webkit-box-sizing: border-box; */
        box-sizing: border-box;
        color: #606266;
        /* display: inline-block; */
        font-size: inherit;
        /* height: 40px; */
        line-height: 40px;
        outline: 0;
        /* padding: 0 15px; */
        /* -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1); */
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        /* width: 100%; */
      }
      /deep/ .el-date-editor .el-range__icon {
        font-size: 14px;
        margin-left: -5px;
        color: #04ffff;
        float: right !important;
        // line-height: 32px;
      }
      /deep/ .el-date-editor .el-range-input {
        /* -webkit-appearance: none; */
        -moz-appearance: none;
        appearance: none;
        border: none;
        outline: 0;
        padding: 0;
        // width: 39%;
        font-size: 12px;
        font-family: PingFang SC;
        // font-weight: bold;
        color: #fff;
        background: transparent;
      }
      /deep/ .el-date-editor .el-range-separator {
        /* padding: 0 5px; */
        /* line-height: 32px; */
        width: fit-content;
        color: #ffffff;
        font-size: 12px;
        font-family: PingFang SC;
        // font-weight: bold;
      }
    }
    &-bottom {
      height: 39px;
      background: transparent;
      display: flex;
      justify-content: center;
      padding-top: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
    .h-line {
      width: 100%;
      height: 1px;
      background: #162a5f;
    }
  }
  .permission-dialog {
    /deep/ .el-dialog__wrapper {
      backdrop-filter: saturate(150%) blur(4px);
      -webkit-backdrop-filter: saturate(150%) blur(4px);
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
<style lang="less">
.popper-class-date {
  background: rgba(9, 31, 47, 0.92);
  border: 1px solid #3c87d8;
  height: fit-content;
  width: 513px;
  max-height: 267px;
  left: auto !important;
  right: 0px !important;
  top: 30px !important;
  .el-date-range-picker__header {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #08ecf1;
  }
  .popper__arrow {
    top: -7px !important;
    left: 90% !important;
    margin-right: 3px;
    border-top-width: 0;
    border-bottom-color: #3c87d8 !important;
  }
  .popper__arrow::after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border-bottom-color: #091f2f !important;
  }
  .el-picker-panel__icon-btn {
    font-size: 12px;
    color: #abd6f0;
    border: 0;
    background: 0 0;
    cursor: pointer;
    outline: 0;
    margin-top: 8px;
  }
  .el-icon-arrow-right:before {
    content: "\e6e0";
  }
  .el-date-table td.next-month,
  .el-date-table td.prev-month {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #7c8695;
  }
  .el-date-table td.disabled div {
    background-color: transparent;
    opacity: 1;
    cursor: not-allowed;
    color: #7c8695;
  }
  // .el-picker-panel {
  //   font-size: 12px;
  //   font-family: PingFang SC;
  //   font-weight: bold;
  //   color: #abd6f0;
  // }
  .el-date-table td.available {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #abd6f0;
  }
  .el-date-table td.today {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #08d8dd;
  }

  .popper-class-date .el-date-table td.today {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #08d8dd;
    background: #020308;
  }
  .el-date-table td.in-range div,
  .el-date-table td.in-range div:hover,
  .el-date-table.is-week-mode .el-date-table__row.current div,
  .el-date-table.is-week-mode .el-date-table__row:hover div {
    // 选中hover背景色
    background: rgba(153, 192, 216, 0.2);
    color: #08d8dd;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .el-date-table td.today span {
    color: #08d8dd;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
  }

  .el-date-table td.end-date span,
  .el-date-table td.start-date span {
    background-color: rgba(153, 192, 216, 0);
  }

  .el-date-table td {
    width: 32px;
    padding: 0px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  .el-date-table td.start-date div {
    margin-left: 0;
  }
  .el-date-table td.end-date div {
    margin-right: 0;
  }
  .el-picker-panel__content {
    position: relative;
    /* margin: 15px; */
    height: 265px;
  }
  .el-date-range-picker__content.is-left {
    border-right: 1px solid #214e7e;
  }
  .el-date-table th {
    padding: 0px;
    color: #abd6f0;
    font-weight: 400;
    border-bottom: solid 1px #214e7e;
  }
}
.datav-layout {
  width: 100vw;
  height: 100vh;
  background-color: #020308;
  overflow: hidden;
}
.jc-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
* {
  box-sizing: border-box;
}
</style>
