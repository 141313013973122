<template>
  <div id="centerLeft1">
    <!-- <div v-if="etype === 2" class="bg-color-black">
      <div class="d-flex jc-center">
        <CenterLeft2Chart
          :etype="etype"
          v-loading="loading"
          element-loading-background="rgba(0,0,0,0)"
          :cdata="cdata"
        />
      </div>
    </div> -->
    <div class="bg-color-black school-gif">
      <img :src="etype === 3 ? schoolLoadImg : platformLoadImg" alt="" />
    </div>
  </div>
</template>

<script>
// import CenterLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
export default {
  props: {
    etype: {
      type: Number,
      default: -1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    orgCode: {
      type: [String, Number],
      default: "",
    },
    areaName: {
      type: String,
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cdata: {},
      schoolLoadImg: require("@/assets/images/school-load.gif"),
      platformLoadImg: require("@/assets/images/platform-load.png"),
      loading: false,
    };
  },
  components: {
    // CenterLeft2Chart,
  },
  computed: {
    trueCode() {
      if (this.etype === 2) {
        return this.areaCode;
      } else if (this.etype === 3) {
        return this.schoolCode;
      } else if (this.etype === 4) {
        return this.orgCode;
      } else {
        return "";
      }
    },
    sumDate() {
      return this.startDate + this.endDate;
    },
  },
  watch: {
    // trueCode(val) {
    //   console.log("this.etype", this.etype);
    //   console.log("val", val);
    //   if (
    //     (this.etype === 2 && val !== "") ||
    //     this.etype === 1
    //   ) {
    //     this.loading = true;
    //     this.getData();
    //   } else {
    //     this.loading = false;
    //   }
    // },
    sumDate(val) {
      if (val) {
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      this.loading = true;
      if (this.etype === 2) {
        let cdata = {
          data: {},
          jsonName: "area",
          areaName: this.areaName,
        };
        if (this.trueCode) {
          this.$store
            .dispatch("getJsonCodeData", this.trueCode)
            .then((res) => {
              if (res.success === true) {
                if (res.data) {
                  cdata.data = res.data;
                  this.cdata = cdata;
                  this.$echarts.registerMap("area", res.data);
                }
              } else {
                this.cdata = cdata;
              }
              this.loading = false;
            })
            .catch(() => {
              this.cdata = {
                jsonName: "no-data",
              };
              this.loading = false;
            });
        }
      } else if (this.etype === 1) {
        // this.cdata = {
        //   jsonName: "china",
        //   areaName: this.areaName,
        // };
        this.loading = false;
      }
      console.log("this.cdata", this.cdata);
    },
  },
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
  $box-width: 300px;
  $box-height: 421px;
  padding: 5px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  margin-top: 10px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
  .school-gif {
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
