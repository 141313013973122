<template>
  <div>
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="averageUseTime"
      height="300px"
      width="100%"
      @legendselectchanged="legendselectchanged"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import emptyChart from "@/components/echart/empty";
export default {
  data() {
    return {
      options: {},
      // placeholderData:[0, 1, 2, 3, 4, 5,],
      placeholderData: [],
      legendData: [
        this.$t("el.dataAnalysis.preparationTime"),
        this.$t("el.board.DurationTeaching"),
      ],
    };
  },
  components: {
    Echart,
    // emptyChart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    legendselectchanged(params) {},
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          title: {
            text: "",
            subtext: "",
          },
          tooltip: {
            trigger: "axis",
            // formatter: function(value) {return value*100+'%'},
            formatter: function (seriesData, ticket, callback) {
              let showHtml = "";
              let top = "";
              seriesData.forEach((item, i) => {
                let icon = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:8px;height:8px;background-color:${item.color}"></span>`;
                let name = item.seriesName;
                let Xname = item.name;
                let Yname = item.data;
                // let count = item.data.count;
                // let marker = item.marker;
                top = Xname;
                showHtml = showHtml + "</br>" + icon + name + Yname;
              });
              return top + showHtml;
            },
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            data: this.cdata.category.length > 0 ? this.legendData : [],
            icon: "circle",
            type: "plain", // 图例的类型 'plain':普通图例  'scroll':可滚动翻页的图例
            zlevel: 1, // 所有图形的 zlevel 值。
            align: "left",
            top: "12%", // bottom:"20%" // 组件离容器的距离
            left: "center", // left:"10%"  // // 组件离容器的距离
            width: "auto", // 图例组件的宽度
            height: "auto", // 图例组件的高度
            itemGap: 34,
            padding: [0, 0, 0, 0],
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              padding: [0, 0, 0, 5],
              color: "#7C8695",
              fontWeight: 400,
            },
            orient: "horizontal", // 图例列表的布局朝向。 'horizontal'  'vertical'
          },
          // toolbox: {
          //   show: true,
          //   feature: {
          //     dataView: { show: true, readOnly: false },
          //     magicType: { show: true, type: ["line", "bar"] },
          //     restore: { show: true },
          //     saveAsImage: { show: true },
          //   },
          // },
          calculable: true,
          xAxis: [
            {
              type: "category",
              // prettier-ignore
              // data: ['11-29', '11-30', '12-01', '12-02', '12-03', '12-04', '12-05'],
              axisLine: {
                show: true,
                lineStyle: {
                  color: "rgba(255,255,255,.2)",
                },
              },
              axisLabel: {
                textStyle: {
                  color: "#04FFFF",
                },
              },
              boundaryGap: true,
              data: this.cdata.category,
            },
          ],
          yAxis: [
            {
              type: "value",
              // max: 1,
              // min: 0,
              splitNumber: 5,
              axisLine: {
                show: false,
                lineStyle: {
                  color: "rgba(255,255,255,.2)",
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "rgba(255,255,255,.2)",
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#04FFFF",
                },
                // internal:'auto',
                // formatter: function(value) {
                //   return value * 100 + "%";
                // },
              },
            },
          ],
          dataZoom: [
            {
              show: this.cdata.category.length > 5, // 是否显示
              backgroundColor: "rgba(100,172,212,.1)",
              height: "20",
              type: "slider",
              dataBackground: {
                lineStyle: {
                  color: "rgba(14, 219, 219, 0.25)",
                },
                areaStyle: { color: "rgba(14, 219, 219, 0.25)" },
              },
              fillerColor: "rgba(14,219,219,0.2)",
              startValue: this.cdata.category[0],
              endValue:
                this.cdata.category.length > 5
                  ? this.cdata.category[4]
                  : this.cdata.category[this.cdata.category.length],
              // start: 0,
              // end: 10,
            },
          ],
          series: [
            // {
            //   name: "Placeholder",
            //   type: "bar",
            //   stack: "Total",
            //   itemStyle: {
            //     borderColor: "transparent",
            //     color: "transparent",
            //   },
            //   emphasis: {
            //     itemStyle: {
            //       borderColor: "transparent",
            //       color: "transparent",
            //     },
            //   },
            //   data: this.placeholderData,
            // },
            {
              name: this.$t("el.dataAnalysis.preparationTime"),
              type: "bar",
              color: "#F3B229",
              barMaxWidth: 12,
              barGap: "0%" /* 多个并排柱子设置柱子之间的间距*/,
              //  barCategoryGap:'0%',/*多个并排柱子设置柱子之间的间距*/
              // data: [
              //   2.0,
              //   4.9,
              //   7.0,
              //   23.2,
              //   25.6,
              //   76.7,
              //   135.6,
              //   162.2,
              //   32.6,
              //   20.0,
              //   6.4,
              //   3.3,
              // ],
              data: this.cdata.bkAvgData,
            },
            {
              name: this.$t("el.board.DurationTeaching"),
              type: "bar",
              color: "#A76FEB",
              barMaxWidth: 12,
              barGap: "0%" /* 多个并排柱子设置柱子之间的间距*/,
              //  barCategoryGap:'0%',/*多个并排柱子设置柱子之间的间距*/
              // data: [
              //   2.6,
              //   5.9,
              //   9.0,
              //   26.4,
              //   28.7,
              //   70.7,
              //   175.6,
              //   182.2,
              //   48.7,
              //   18.8,
              //   6.0,
              //   2.3,
              // ],
              data: this.cdata.skAvgData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
