<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="255px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import { toFixed5 } from "@/utils";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              var result = "";
              let spanHtml = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:8px;height:8px;background-color:${params.color}"></span>`;
              let percent = toFixed5(params.value * 100, 1);
              result = spanHtml + `${params.name} ${percent}%`;
              return result;
            },
          },
          legend: {
            show: true, // 是否显示
            type: "plain", // 图例的类型 'plain':普通图例  'scroll':可滚动翻页的图例
            zlevel: 1, // 所有图形的 zlevel 值。
            align: "left",
            top: "top", // bottom:"20%" // 组件离容器的距离
            // right: "right", // left:"10%"  // // 组件离容器的距离
            right: "20px", // left:"10%"  // // 组件离容器的距离
            orient: "vertical", // 图例列表的布局朝向。 'horizontal'  'vertical'
            borderWidth: 1,
            borderColor: "#203D8A",
            padding: [7, 8],
            itemWidth: 8,
            itemHeight: 8,
            icon: "circle",
            textStyle: {
              padding: [0, 0, 0, 10],
              color: "#7C8695",
              fontWeight: 400,
            },
            formatter: function (name) {
              return name.length > 7 ? name.substr(0, 7) + "..." : name;
            },
            tooltip: {
              show: true,
            },
          },
          label: {
            fontWeight: 400,
            fontSize: 12,
            formatter: function (params) {
              // var result =  `${params.name}\n${params.percent}%`;
              var result = `${params.name}`;
              return result;
            },
          },
          series: [
            {
              type: "pie",
              // radius: ["40%", "70%"],
              radius: ["45%", "80%"],
              center: ["40%", "50%"],
              data: newData.data,
              // data: [],
              avoidLabelOverlap: true,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              hoverAnimation: false,
              // itemStyle: {
              //   normal: {
              //     borderWidth: 1,
              //     label: {
              //       show: true,
              //     },
              //     labelLine: {
              //       show: true,
              //       length: 0.001,
              //     },
              //   },
              // },
              itemStyle: {
                normal: {
                  borderWidth: 1,
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                },
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
