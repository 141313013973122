<template>
  <div id="centerRight2">
    <div
      :class="['d-flex', 'flex-title']"
      :style="$i18n.locale === 'zh_CN' ? 'top: -2%' : 'top: -5%'"
    >
      <span class="fs-xl text">
        {{
          $i18n.locale === "zh_CN"
            ? typeName + $t("el.board.RankingLesson") + " (min)"
            : $t("el.board.RankingLesson") + typeName + " (min)"
        }}<hover-component
          :content="content"
          name="question-circle"
        ></hover-component
      ></span>
    </div>
    <dv-border-box-8
      v-loading="loading"
      element-loading-background="rgba(0,0,0,.2)"
      :dur="0"
      :color="['#3678C0']"
    >
      <span @click="showDialog = true" class="charts-more">{{
        $t("el.board.More")
      }}</span>
      <div class="bg-color-black">
        <div v-if="!isEmpty" class="d-flex ai-center flex-column body-box">
          <centerRight2Chart1 :cdata="cdata" />
        </div>
        <empty-chart v-else></empty-chart>
      </div>
    </dv-border-box-8>
    <dialog-component @closeDialog="showDialog = false" v-if="showDialog">
      <template slot="header">
        <p class="d-title">
          {{
            $i18n.locale === "zh_CN"
              ? "各" + typeName + "备授课时长排行(min)"
              : "Rank of hours of each " + typeName + "'s lesson preparation"
          }}
        </p>
      </template>
      <template slot="xm-table">
        <xm-table
          ref="xmTable"
          :fatch-method="fatchMethod"
          :form-items="formItems"
          :columns="columns"
          :formOptions="{
            hiddenDefaultFormBtn: true,
          }"
          :tableOptions="{
            stripe: true,
            border: false,
          }"
          :class="computedClassName"
          :showPagination="isShowPagination"
          :sreenEmpty="true"
          :defaultLimit="5"
          :autoLoad="true"
        >
          <template slot="formBtn">
            <el-button
              type="primary"
              @click="query()"
              size="small"
              class="teachManage-tool-btnicon"
              >{{ $t("el.common.search") }}</el-button
            >
          </template>
        </xm-table>
      </template>
      <template slot="footer">
        <div v-show="isShowPagination" class="f-button">
          <el-button
            :loading="exportLoading"
            @click="exportData"
            type="primary"
            size="small"
            >{{ $t("el.common.export") }}
          </el-button>
        </div>
      </template>
    </dialog-component>
  </div>
</template>

<script>
import hoverComponent from "@/components/echart/hover/index";
import dialogComponent from "@/components/echart/dialog/index";
import centerRight2Chart1 from "@/components/echart/centerRight/centerRightChart";
import emptyChart from "@/components/echart/empty";
import { handparams } from "../../../utils";
import { exportBskStatisticsData } from "@/api/screen";

export default {
  props: {
    etype: {
      type: Number,
      default: -1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    orgCode: {
      type: [String, Number],
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
  },
  components: {
    centerRight2Chart1,
    hoverComponent,
    dialogComponent,
    emptyChart,
  },
  data() {
    return {
      showDialog: false,
      currentPage1: 5,
      schoolList: [],
      isShowPagination: false,
      cdata: {
        indicatorData: {
          bkData: [],
          skData: [],
        },
        cityData: [],
      },
      isEmpty: false,
      loading: true,
      exportLoading: false,
    };
  },
  watch: {
    sumDate(val) {
      if (val) {
        this.getData();
      }
    },
  },
  computed: {
    sumDate() {
      return this.startDate + this.endDate;
    },
    trueCode() {
      if (this.etype === 2) {
        return this.areaCode;
      } else if (this.etype === 3) {
        return this.schoolCode;
      } else if (this.etype === 4) {
        return this.orgCode;
      } else {
        return "";
      }
    },
    formItems() {
      return [
        {
          itemType: "input",
          prop: "name",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: `${this.typeName}${this.$t("el.common.name")}`,
          itemStyle: "width:142px;height:32px;",
          // labelWidth: "84px",
          clearable: true,
        },
      ];
    },
    computedClassName() {
      if (!this.isShowPagination) {
        return "empty-data";
      } else {
        return "";
      }
    },
    // 根据类型 展示不同名称
    typeName() {
      let type;
      switch (this.etype) {
        case 1:
        case 4:
          type = this.$t("el.board.areaData");
          break;
        case 2:
          type = this.$t("el.board.school");
          break;
        case 3:
          type = this.$t("el.board.teachers");
          break;
        default:
          type = "";
      }
      return type;
    },
    // hover内容
    content() {
      let text;
      switch (this.etype) {
        case 1:
        case 4:
          text = `<p>${this.$t(
            "el.board.RankingTimeRange"
          )}</p><ul><li>${this.$t(
            "el.board.AreaLessonDuration"
          )}</li><li>${this.$t(
            "el.board.AreaTeachingDuration"
          )}</li><li>${this.$t("el.board.TotalDurationTime")}</li></ul>`;
          break;
        case 2:
          text = `<p>${this.$t(
            "el.board.RankingTimeRange"
          )}</p><ul><li>${this.$t(
            "el.board.SchoolPreparationDuration"
          )}</li><li>${this.$t(
            "el.board.SchoolTeachingDuration"
          )}</li><li>${this.$t("el.board.TotalTeachingDuration")}</li></ul>`;
          break;
        case 3:
          text = `<p>${this.$t(
            "el.board.RankingTimeRange"
          )}</p><ul><li>${this.$t(
            "el.board.LessonPreparationTeachers"
          )}</li><li>${this.$t(
            "el.board.TeachingDurationTeachers"
          )}</li><li>${this.$t("el.board.TotalDurationTeacher")}</li></ul>`;
          break;
        default:
          text = "";
      }
      return text;
    },
    columns() {
      return [
        {
          label: `${this.typeName}${this.$t("el.common.name")}`,
          prop: "name",
        },
        {
          label: this.$t("el.dataAnalysis.preparationTime"),
          prop: "bkTotalTime",
        },
        {
          label: this.$t("el.board.DurationTeaching"),
          prop: "skTotalTime",
        },
        {
          label: this.$t("el.board.TotalDuration"),
          prop: "bkSkTotalTime",
        },
      ];
    },
  },
  methods: {
    // 导出
    exportData() {
      console.log(this.$refs["xmTable"].getQueryParams());
      this.exportLoading = true;
      let paramsName = this.$refs["xmTable"].getQueryParams().name || "";
      // let startDate = encodeURI(this.startDate);
      // let endDate = encodeURI(this.endDate);
      // let url =
      //   process.env.VUE_APP_BASE_API +
      //   `/statistics/bskExport?kanbanType=${this.etype}&startDate=${startDate}&endDate=${endDate}&name=${paramsName}`;
      // if (this.etype !== 1) {
      //   url += `&code=${this.trueCode}`;
      // }
      // let a = document.createElement("a");
      // a.setAttribute("href", url);
      // a.click();
      const form = {
        kanbanType: this.etype,
        startDate: this.startDate,
        endDate: this.endDate,
        name: paramsName,
      };
      if (this.etype !== 1) {
        form.code = this.trueCode;
      }
      exportBskStatisticsData(form)
        .then((res) => {
          let url = window.URL.createObjectURL(res);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          console.log(url, "url=====");
          link.setAttribute(
            "download",
            form.startDate.substring(0, 10) +
              this.$t("el.common.to") +
              form.endDate.substring(0, 10) +
              this.typeName +
              this.$t("el.board.RankingLesson") +
              ".xlsx"
          );
          console.log("link====", link);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error("捕获到错误：", err);
          if (err.response) {
            console.error("响应数据：", err.response.data);
            console.error("响应状态码：", err.response.status);
          }
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    // 获取柱状图数据
    async getData() {
      this.loading = true;
      let params = {
        kanbanType: this.etype,
        code: this.trueCode,
        offset: 0,
        limit: 5,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      await this.$store
        .dispatch("getBskRank", handparams(params))
        .then((res) => {
          let data = res.data.records;
          if (data.length > 0) {
            this.cdata.cityData = data.map((item) => item.name).reverse();
            this.cdata.indicatorData.bkData = data
              .map((item) => item.bkTotalTime)
              .reverse();
            this.cdata.indicatorData.skData = data
              .map((item) => item.skTotalTime)
              .reverse();
            this.isEmpty = false;
          } else {
            this.isEmpty = true;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    query() {
      this.$refs["xmTable"].query();
    },
    // 获取表格数据
    fatchMethod(params) {
      console.log("params", params);
      let form = {
        kanbanType: this.etype,
        code: this.trueCode,
        offset: params.offset,
        limit: params.limit,
        name: params.name,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      return this.$store
        .dispatch("getBskRank", handparams(form))
        .then((res) => {
          if (res.data.records.length === 0) {
            this.isShowPagination = false;
          } else {
            this.isShowPagination = true;
          }
          return res;
        })
        .catch(() => {
          this.isShowPagination = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#centerRight2 {
  $box-height: 305px;
  $box-width: 100%;
  padding: 5px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;

  .bg-color-black {
    // padding: 5px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
    // padding-top: 30px;
  }
  .flex-title {
    width: max-content;
    z-index: 999;
    position: absolute;
    background: #162a5f;
    line-height: 25px;
    padding: 0 20px;
    text-align: center;
    transform: translateX(-50%);
    left: 50%;
    max-width: 70%;
    .text {
      font-size: 16px;
      color: #04ffff;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      word-break: break-all;
    }
    ::v-deep .icon-component-tooltip {
      right: -145px;
    }
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 10px;
    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
  .charts-more {
    position: absolute;
    right: 25px;
    top: 20px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    z-index: 99;
  }
  .echarts-dialog {
    .teachManage-tool-btnicon {
      margin-left: 10px;
    }
  }
  .f-button {
    ::v-deep .el-button {
      > span {
        width: auto !important;
      }
    }
  }
}
</style>
