<template>
  <div class="board-select">
    <el-select
      :size="item.size"
      v-model="formData[item.prop]"
      v-bind="item"
      @change="onSelectChange"
      :popper-append-to-body="false"
      :style="item.itemStyle || 'width: 200px;'"
    >
      <template v-if="item.options && item.options.length">
        <el-option
          v-for="option in item.options"
          :label="option.name"
          :value="option.id"
          :key="option.id"
        />
      </template>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "BoardDialog",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onSelectChange(val) {
      // console.log("val", val);
      this.$emit("onSelectChange", val);
    },
  },
};
</script>

<style lang="less">
.popper-class-select {
  background: rgba(9, 31, 47, 0.92);
  border: 1px solid #3c87d8;
  height: fit-content;
  position: absolute !important;
  top: auto !important;
  right: 0 !important;
  left: auto !important;
  .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 16px !important;
  }
  .el-form-item__content {
    line-height: 16px;
    position: relative;
    font-size: 14px;
  }
  .el-input__inner {
    -webkit-appearance: none;
    background-color: transparent;
    background-image: none;
    border-radius: 4px;
    border: 0px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-block;
    font-size: inherit;
    height: 16px !important;
    line-height: 16px !important;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    vertical-align: bottom !important;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #154263;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .el-select-dropdown__item.selected {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #04ffff;
  }
  .el-select-dropdown__item {
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 34px;
    line-height: 34px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #abd6f0;
  }
  .el-select-dropdown__empty {
    padding: 10px 0;
    margin: 0;
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #abd6f0;
  }
  .popper__arrow {
    top: -7px !important;
    left: 65% !important;
    margin-right: 3px;
    border-top-width: 0;
    border-bottom-color: #3c87d8 !important;
  }
  .popper__arrow::after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border-bottom-color: #091f2f !important;
  }
}
</style>
<style lang="less" scoped>
.board-select {
  margin-top: 18px;
  /deep/ .el-input__inner {
    -webkit-appearance: none;
    background-color: rgba(19, 56, 91, 0.76);
    background-image: none;
    border-radius: 4px;
    border: 0px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-block;
    font-size: inherit;
    height: 32px !important;
    line-height: 32px !important;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    border: 1px solid #3678c0;
  }
  /deep/ .el-select .el-input__inner {
    vertical-align: bottom !important;
  }
}
</style>
