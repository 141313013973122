<template>
  <div id="courseHeatRanking" class="course-heat-ranking">
    <div
      class="d-flex flex-title"
      :class="$i18n.locale === 'zh_CN' ? 'pop-zh' : 'pop-en'"
    >
      <span class="fs-xl text"
        >{{ $t("el.board.CoursePopularityRanking")
        }}<hover-component
          style="text-align: left"
          :content="content"
          name="question-circle"
        ></hover-component
      ></span>
    </div>
    <dv-border-box-8
      :color="['#3678C0']"
      :dur="0"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.2)"
    >
      <div class="flex" style="padding-top: 22px">
        <xm-form
          ref="form"
          class="class-form flex"
          :form-items="formItems"
          :form-options="newFormOptions"
          :form-data="queryParams"
          @submit.native.prevent
        >
        </xm-form>
        <span @click="showDialog = true" class="charts-more">{{
          $t("el.board.More")
        }}</span>
      </div>
      <div class="bg-color-black">
        <empty-chart v-if="isEmpty" class="empty-data-box"></empty-chart>
        <div class="d-flex ai-center flex-column body-box">
          <!-- <dv-capsule-chart class="dv-cap-chart" :config="config" /> -->
          <dv-scroll-board
            :config="config"
            style="
              width: 90%;
              height: 240px;
              color: #abd6f0;
              text-align: center;
            "
            v-if="!isEmpty"
            @mouseover="getRowdata"
          />
        </div>
      </div>
    </dv-border-box-8>
    <dialog-component @closeDialog="showDialog = false" v-if="showDialog">
      <template slot="header">
        <p class="d-title">{{ $t("el.board.CoursePopularityRanking") }}</p>
      </template>
      <template slot="xm-table">
        <xm-table
          ref="xmTable"
          :fatch-method="fatchMethod"
          :form-items="dialogFormItems"
          :columns="columns"
          :formOptions="{
            hiddenDefaultFormBtn: true,
          }"
          :tableOptions="{
            stripe: true,
            border: false,
          }"
          :class="computedClassName"
          :showPagination="false"
          :sreenEmpty="true"
          :defaultLimit="5"
          :autoLoad="true"
        >
          <template slot="heatSpan" slot-scope="scope">
            <img :src="fire1" alt="" class="fire" v-if="scope.row.rank === 1" />
            <img :src="fire2" alt="" class="fire" v-if="scope.row.rank === 2" />
            <img :src="fire3" alt="" class="fire" v-if="scope.row.rank === 3" />
            {{ scope.row.heat | getPercentNumber }}
          </template>
          <template slot="formBtn">
            <div class="slot-btns">
              <el-button
                type="primary"
                @click="query()"
                size="small"
                class="teachManage-tool-btnicon"
                >{{ $t("el.common.search") }}</el-button
              >
              <el-button
                type="primary"
                @click="reset()"
                size="small"
                class="teachManage-tool-btnicon"
                >{{ $t("el.common.reset") }}</el-button
              >
            </div>
          </template>
        </xm-table>
      </template>
      <template slot="footer">
        <div v-show="isShowPagination" class="f-button">
          <el-button
            :loading="exportLoading"
            type="primary"
            size="small"
            @click="exportFile"
          >
            <a target="_blank" style="color: #04ffff">{{
              $t("el.common.export")
            }}</a>
          </el-button>
        </div>
      </template>
    </dialog-component>
  </div>
</template>

<script>
import XmForm from "../../../components/global/xm-form.vue";
import dialogComponent from "@/components/echart/dialog/index";
import emptyChart from "@/components/echart/empty";
import { getCourseHeat } from "@/api/screen";
import { toFixed5 } from "@/utils";
import { handparams } from "../../../utils";
import { exportCourseHeatData } from "@/api/screen";

export default {
  props: {
    etype: {
      type: Number,
      default: -1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    orgCode: {
      type: [String, Number],
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    propertyGradeData: {
      type: Array,
      default: () => [],
    },
    propertySubjectData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    XmForm,
    dialogComponent,
    emptyChart,
  },
  data() {
    // let queryParams = {};
    // this.formItems &&
    //   this.formItems.forEach((item) => {
    //     queryParams[item.prop] = "";
    //   });
    return {
      fire1: require("@/assets/images/icon/fire1.png"),
      fire2: require("@/assets/images/icon/fire2.png"),
      fire3: require("@/assets/images/icon/fire3.png"),
      loading: true,
      isEmpty: false, // 是否为空
      showDialog: false, // 弹窗显示
      isShowPagination: true, // 是否显示页码
      queryParams: {
        gradeId: null,
        sortType: 1,
      }, // 查询参数
      nowQueryParams: {}, // 当前查询参数
      newFormOptions: {},
      dataArr: [],
      exportLoading: false,
    };
  },
  watch: {
    sumDate(val) {
      if (val) {
        this.getData();
      }
    },
    queryParams: {
      handler() {
        // 如果等于1触发获取数据
        this.getData();
      },
      deep: true,
    },
  },
  computed: {
    config() {
      return {
        header: [
          `<span style='color:#04FFFF'>${this.$t("el.board.Ranking")}</span>`,
          `<span style='color:#04FFFF'>${this.$t(
            "el.planCourse.CourseName"
          )}</span>`,
          "<span style='color:#04FFFF'>" +
            this.$t("el.schoolResourceManage.grade") +
            "</span>",
          "<span style='color:#04FFFF'>" +
            this.$t("el.schoolResourceManage.subjects") +
            "</span>",
          `<span style='color:#04FFFF'>${this.$t(
            "el.board.Popularity"
          )}</span>`,
        ],
        headerBGC: "rgba(0,0,0,0)",
        align: "center",
        oddRowBGC: "rgba(0,0,0,0)",
        evenRowBGC: "rgba(100,172,212,0.1)",
        carousel: "",
        data: this.dataArr,
      };
    },
    gradeList() {
      let gradeList = this.propertyGradeData.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      return gradeList;
    },
    subjectList() {
      return this.propertySubjectData.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    },
    content() {
      switch (this.etype) {
        case 1:
        case 4:
          return `<p>${this.$t(
            "el.board.PopularityValueRanking"
          )}</p><p>${this.$t("el.board.PlatformValueDesc")}</p>`;
        case 2:
          return `<p>${this.$t(
            "el.board.AreaPopularityValueRanking"
          )}</p><p>${this.$t("el.board.AreaValueDesc")}</p>`;
        case 3:
          return `<p>${this.$t(
            "el.board.SchoolPopularityValueRanking"
          )}</p><p>${this.$t("el.board.SchoolValueDesc")}</p>`;
        default:
          return "";
      }
    },
    sumDate() {
      return this.startDate + this.endDate;
    },
    trueCode() {
      if (this.etype === 2) {
        return this.areaCode;
      } else if (this.etype === 3) {
        return this.schoolCode;
      } else if (this.etype === 4) {
        return this.orgCode;
      } else {
        return "";
      }
    },
    formItems() {
      return [
        {
          itemType: "select",
          prop: "gradeId",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: "",
          options: [
            {
              value: null,
              label: this.$t("el.courseList.allGrade"),
            },
            ...this.gradeList,
          ],
          "popper-class": "popper-class",
          popper__arrow: "popper__arrow",
          "popper-append-to-body": false,
          itemStyle: "width:90px;margin-left:28px",
        },
        {
          itemType: "select",
          prop: "sortType",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: "",
          options: [
            {
              value: 1,
              label: this.$t("el.board.TopFive"),
            },
            {
              value: 2,
              label: this.$t("el.board.LastFive"),
            },
          ],
          "popper-class": "popper-class",
          popper__arrow: "popper__arrow",
          "popper-append-to-body": false,
          itemStyle:
            this.$i18n.locale === "zh_CN"
              ? `width:76px;margin-left:28px`
              : `width:110px;margin-left:28px`,
        },
      ];
    },
    dialogFormItems() {
      return [
        {
          itemType: "input",
          prop: "courseName",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.CourseName"),
          itemStyle: "width:142px;height:32px;",
          // labelWidth: "84px",
          clearable: true,
        },
        {
          itemType: "select",
          prop: "gradeId",
          placeholder: this.$t("el.planCourse.choose"),
          label: this.$t("el.courseList.grade"),
          "popper-class": "popper-class",
          popper__arrow: "popper__arrow",
          "popper-append-to-body": false,
          itemStyle: "width:142px;height:32px;",
          labelWidth: "60px",
          clearable: true,
          options: [...this.gradeList],
        },
        {
          itemType: "select",
          prop: "subjectId",
          placeholder: this.$t("el.planCourse.choose"),
          label: this.$t("el.courseList.subject"),
          "popper-class": "popper-class",
          popper__arrow: "popper__arrow",
          "popper-append-to-body": false,
          itemStyle: "width:142px;height:32px;",
          labelWidth: "60px",
          clearable: true,
          options: this.subjectList,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("el.board.Ranking"),
          prop: "rank",
        },
        {
          label: this.$t("el.planCourse.CourseName"),
          prop: "courseName",
        },
        {
          label: this.$t("el.schoolResourceManage.grade"),
          prop: "gradeName",
        },
        {
          label: this.$t("el.schoolResourceManage.subjects"),
          prop: "subjectName",
        },
        {
          label: this.$t("el.board.Popularity"),
          prop: "heat",
          slotName: "heatSpan",
        },
      ];
    },
    computedClassName() {
      if (!this.isShowPagination) {
        return "empty-data";
      } else {
        return "";
      }
    },
  },
  filters: {
    getPercentNumber(val) {
      return toFixed5(val * 100, 1) + "%";
    },
  },
  created() {
    this.$set(this.queryParams, "gradeId", null);
    this.$set(this.queryParams, "sortType", 1);
    console.log(this.propertyGradeData);
  },
  methods: {
    getRowdata(val) {
      console.log(this.gradeList);
      console.log(val.ceil, "jhkdfkjhdfjkh");
    },
    async getData() {
      this.loading = true;
      let params = {
        kanbanType: this.etype,
        startDate: this.startDate,
        endDate: this.endDate,
        code: this.trueCode,
        limit: 5,
        gradeId: this.queryParams.gradeId,
        sortType: this.queryParams.sortType,
      };
      await getCourseHeat(handparams(params))
        .then((res) => {
          let data = res.data.records;
          let fireSpan1 =
            "<span><img class='fire' style='width:10px; height:12px;margin-right:6px;' src='" +
            this.fire1 +
            "'>";
          let fireSpan2 =
            "<span><img class='fire' style='width:10px; height:12px;margin-right:6px;' src='" +
            this.fire2 +
            "'>";
          let fireSpan3 =
            "<span><img class='fire' style='width:10px; height:12px;margin-right:6px;' src='" +
            this.fire3 +
            "'>";
          if (data.length === 0) {
            this.isEmpty = true;
            this.loading = false;
          } else {
            this.isEmpty = false;
            this.loading = false;
            let dataArr = [];
            data.forEach((item) => {
              dataArr.push([
                item.rank,
                '<span data-title="' +
                  item.courseName +
                  '"class=' +
                  (item.courseName.length > 5 ? "xy-title" : "") +
                  ">" +
                  item.courseName +
                  "</span>",
                '<span data-title="' +
                  item.gradeName +
                  '"class=' +
                  (item.gradeName.length > 5 ? "xy-title" : "") +
                  ">" +
                  item.gradeName +
                  "</span>",
                '<span data-title="' +
                  item.subjectName +
                  '"class=' +
                  (item.subjectName.length > 5 ? "xy-title" : "") +
                  ">" +
                  item.subjectName +
                  "</span>",
                // item.subjectName,
                item.rank === 1
                  ? fireSpan1 +
                    // Number(Number(item.heat * 100).toFixed(1))
                    toFixed5(item.heat * 100, 1) +
                    "%" +
                    "</span>"
                  : item.rank === 2
                  ? fireSpan2 +
                    // Number(Number(item.heat * 100).toFixed(1))
                    toFixed5(item.heat * 100, 1) +
                    "%" +
                    "</span>"
                  : item.rank === 3
                  ? fireSpan3 +
                    // Number(Number(item.heat * 100).toFixed(1))
                    toFixed5(item.heat * 100, 1) +
                    "%" +
                    "</span>"
                  : toFixed5(item.heat * 100, 1) + "%",
              ]);
            });
            // this.config = {
            //   header: [
            //     `<span style='color:#04FFFF'>${this.$t(
            //       "el.board.Ranking"
            //     )}</span>`,
            //     `<span style='color:#04FFFF'>${this.$t(
            //       "el.planCourse.CourseName"
            //     )}</span>`,
            //     "<span style='color:#04FFFF'>" +
            //       this.$t("el.schoolResourceManage.grade") +
            //       "</span>",
            //     "<span style='color:#04FFFF'>" +
            //       this.$t("el.schoolResourceManage.subjects") +
            //       "</span>",
            //     `<span style='color:#04FFFF'>${this.$t(
            //       "el.board.Popularity"
            //     )}</span>`,
            //   ],
            //   headerBGC: "rgba(0,0,0,0)",
            //   align: "center",
            //   oddRowBGC: "rgba(0,0,0,0)",
            //   evenRowBGC: "rgba(100,172,212,0.1)",
            //   carousel: "",
            //   data: this.dataArr,
            // };
            this.dataArr = dataArr;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    // 获取表格数据
    fatchMethod(params) {
      let form = {
        // offset: params.offset,
        // limit: params.limit,
        kanbanType: this.etype,
        startDate: this.startDate,
        endDate: this.endDate,
        code: this.trueCode,
        gradeId: params.gradeId,
        subjectId: params.subjectId,
        courseName: params.courseName,
      };
      return getCourseHeat(handparams(form))
        .then((res) => {
          if (res.data.records.length === 0) {
            this.isShowPagination = false;
          } else {
            this.isShowPagination = true;
          }
          return res;
        })
        .catch(() => {
          this.isShowPagination = false;
        });
    },
    getXY(e) {
      console.log(e.offsetX);
    },
    query() {
      this.$refs["xmTable"].query();
    },
    reset() {
      this.$refs["xmTable"].resetForm();
    },
    // 导出
    exportFile() {
      console.log(this.$refs["xmTable"].getQueryParams());
      this.exportLoading = true;
      let paramsName = this.$refs["xmTable"].getQueryParams().courseName || "";
      let gradeId = this.$refs["xmTable"].getQueryParams().gradeId || "";
      let subjectId = this.$refs["xmTable"].getQueryParams().subjectId || "";
      // let startDate = encodeURI(this.startDate);
      // let endDate = encodeURI(this.endDate);
      // let url =
      //   process.env.VUE_APP_BASE_API +
      //   `/heat/exportCourseHeat?kanbanType=${this.etype}&startDate=${startDate}&endDate=${endDate}&courseName=${paramsName}&gradeId=${gradeId}&subjectId=${subjectId}`;
      // if (this.etype !== 1) {
      //   url += `&code=${this.trueCode}`;
      // }
      // let a = document.createElement("a");
      // a.setAttribute("href", url);
      // a.click();
      const form = {
        kanbanType: this.etype,
        startDate: this.startDate,
        endDate: this.endDate,
        courseName: paramsName,
        gradeId,
        subjectId,
      };
      if (this.etype !== 1) {
        form.code = this.trueCode;
      }
      exportCourseHeatData(form)
        .then((res) => {
          let url = window.URL.createObjectURL(res);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          console.log(url, "url=====");
          link.setAttribute(
            "download",
            form.startDate.substring(0, 10) +
              this.$t("el.common.to") +
              form.endDate.substring(0, 10) +
              this.$t("el.board.CoursePopularityRanking") +
              ".xlsx"
          );
          console.log("link====", link);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error("捕获到错误：", err);
          if (err.response) {
            console.error("响应数据：", err.response.data);
            console.error("响应状态码：", err.response.status);
          }
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// $box-height: 305px;
// $box-width: 100%;
#courseHeatRanking {
  $box-height: 305px;
  $box-width: 100%;
  padding: 5px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
  ::v-deep .echart-dialog-content {
    height: 540px;
  }
  .empty-data-box {
    margin-top: -20px;
  }
  // ::v-deep .dv-scroll-board .rows .ceil{
  //   position: relative;
  //   overflow-y:visible;
  // }
  ::v-deep .xy-title {
    &::before {
      content: attr(data-title);
      display: none;
      padding: 0 10px;
      margin-left: -50px;
      // left: 60px;
      margin-top: -30px;
      position: absolute;
      z-index: 999;
      color: #fff;
      border-radius: 10px;
      background-color: rgba(50, 50, 50, 0.7);
    }
  }
  ::v-deep .xy-title:hover:before {
    display: block;
  }
  .fire {
    width: 10px;
    height: 12px;
    margin-right: 4px;
  }
  .slot-btns {
    width: 190px;
    height: 20px;
    margin-left: 510px;
    margin-bottom: 20px;
  }
  .class-form {
    height: fit-content;
  }
  .bg-color-black {
    // padding: 5px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
    // padding-top: 30px;
  }
  .flex-title {
    // text-align:center;
    // z-index: 999;
    // position: absolute;
    // top: -5px;
    // left: calc(50% - 60px);
    // background: #1b2a5c;
    // padding: 10px 20px;
    // height: 25px;
    width: max-content;
    line-height: 25px;
    text-align: center;
    z-index: 999;
    position: absolute;
    top: -2%;
    left: 50%;
    transform: translateX(-50%);
    background: #162a5f;
    padding: 0 20px;
    .text {
      font-weight: 500;
      font-size: 16px;
      color: #04ffff;
      word-break: break-all;
      text-align: center;
    }
    ::v-deep .icon-component-tooltip {
      right: -198px;
    }
  }
  .pop-zh {
    ::v-deep .icon-component-tooltip {
      right: -198px;
    }
  }
  .pop-en {
    ::v-deep .icon-component-tooltip {
      right: -156px;
    }
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
  .charts-more {
    position: absolute;
    right: 25px;
    top: 20px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    z-index: 99;
  }
  .f-button {
    ::v-deep .el-button {
      > span {
        width: auto !important;
      }
    }
  }
}
.day-class-more {
  width: 50px;
  height: 16px;
  line-height: 16px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-left: auto;
  margin-right: 26px;
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.course-heat-ranking {
  .class-form {
    /deep/ .el-input__icon {
      height: 100%;
      width: 25px;
      text-align: center;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      line-height: 16px !important;
    }
    /deep/ .el-form-item__content {
      line-height: 16px;
      position: relative;
      font-size: 14px;
    }
    /deep/ .el-input__inner {
      -webkit-appearance: none;
      background-color: transparent;
      background-image: none;
      border-radius: 4px;
      border: 0px solid #dcdfe6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #ffffff;
      display: inline-block;
      font-size: inherit;
      height: 16px !important;
      line-height: 16px !important;
      outline: 0;
      padding: 0 0;
      -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
    /deep/ .el-form-item {
      margin-bottom: 0px;
    }
    /deep/ .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #154263;
    }
    /deep/ .el-select-dropdown__item.selected {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #04ffff;
    }
    /deep/ .el-select-dropdown__item {
      padding: 0 20px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 34px;
      line-height: 34px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #abd6f0;
    }
  }
  /deep/ .el-select-dropdown {
    top: auto !important;
    left: auto !important;
    border: 1px solid rgb(60, 135, 216);
    &__empty,
    .el-select-dropdown__list {
      line-height: 20px;
      background: #091f2f;
      color: #abd6f0;
      border-radius: 3px;
    }
    .el-select-dropdown__item {
      color: #abd6f0;
      font-weight: bold;
      font-size: 12px;
      &.hover {
        background-color: rgba(#154263, 68%);
        color: #04ffff !important;
      }
    }
    .el-select-dropdown__item.selected {
      background-color: rgba(#154263, 68%);
      color: #04ffff !important;
    }
    .popper__arrow {
      border-bottom-color: #3c87d8;
      &::after {
        border-bottom-color: #091f2f;
      }
    }
  }
  /deep/ .teachManage-page {
    display: none;
  }
}
/deep/ .el-table__body-wrapper {
  height: 230px;
  overflow-y: auto;
}
.echart-dialog-content {
  /deep/ .el-form-item__label {
    text-align: justify;
    text-align-last: justify;
  }
}
/deep/ .el-select-dropdown__wrap {
  max-height: 230px;
}
</style>
<style lang="less">
.course-heat-ranking {
  .popper-class {
    background: rgba(9, 31, 47, 0.92);
    border: 1px solid #3c87d8;
    height: fit-content;
  }
  .popper__arrow {
    top: -7px !important;
    // left: 65% !important;
    margin-right: 3px;
    border-top-width: 0;
    border-bottom-color: #3c87d8 !important;
  }
  .popper__arrow::after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border-bottom-color: #091f2f !important;
  }
}
</style>
