<template>
  <div>
    <Chart :cdata="averageTimedata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  // inject:["cdata"],
  props: {
    // cdata: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       category: [],
    //       bkAvgData: [],
    //       skAvgData: [],
    //     };
    //   },
    // },
  },
  components: {
    Chart,
  },
  computed: {
    ...mapGetters(["averageTimedata"]),
  },
  mounted() {
    // this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    // setData() {
    //   for (let i = 0; i < this.cdata.barData.length - 1; i++) {
    //     let rate = this.cdata.barData[i] / this.cdata.lineData[i];
    //     this.cdata.rateData.push(rate.toFixed(2));
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped></style>
