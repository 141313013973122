<template>
  <div id="center">
    <div class="up" :class="{ 's-up': [1, 4].indexOf(etype) > -1 }">
      <div
        class="bg-color-black item"
        v-for="(item, index) in titleItem"
        :key="item.title"
      >
        <p class="fw-b fs-xl">
          {{ item.title }}
          <hover-component
            v-if="
              index === titleItem.length - 1 || index === titleItem.length - 2
            "
            style="text-align: left"
            :content="getContent(index)"
            name="question-circle"
          ></hover-component>
        </p>
        <div v-if="show">
          <dv-digital-flop class="dv-dig-flop" :config="item.number" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hoverComponent from "@/components/echart/hover/index";
import CenterChart from "@/components/echart/center/centerChartRate";

export default {
  props: {
    etype: {
      type: Number,
      default: -1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    orgCode: {
      type: [String, Number],
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sumNum: {
        areaNum: 0,
        schoolNum: 0,
        teacherNum: 0,
        studentNum: 0,
        courseNum: 0,
        lessonNum: 0,
        lessonItemNum: 0,
      },
      content: "",
      show: false,
      titleItem: [],
    };
  },
  computed: {
    sumDate() {
      return this.startDate + this.endDate;
    },
    apiType() {
      let name;
      switch (Number(this.etype)) {
        case 1:
          name = "getPlatFormAlData";
          break;
        case 2:
          name = "getAreaAlData";
          break;
        case 3:
          name = "getSchoolAlData";
          break;
        case 4:
          name = "getPlatJgAlData";
          break;
        default:
          name = "";
          break;
      }
      return name;
    },
  },
  watch: {
    sumDate(val) {
      if (val) {
        this.regetData();
      }
    },
    etype() {
      this.show = false;
      this.titleItem = [];
    },
  },
  components: {
    // CenterChart,
    hoverComponent,
  },
  methods: {
    getContent(index) {
      if (index === this.titleItem.length - 1) {
        return this.$t("el.board.AllStudentsTaken");
      } else if (index === this.titleItem.length - 2) {
        return this.$t("el.board.AllKnowledgePoints");
      }
    },
    formatter(number) {
      return this.numFormat(number);
    },
    // 格式化 三位数字 空格;超过9999999，显示9999999+
    numFormat(num) {
      let c;
      if (num > 9999999) {
        c = "9999999+";
      } else {
        c =
          num.toString().indexOf(".") !== -1
            ? num.toLocaleString()
            : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1 ");
      }
      return c;
    },
    async regetData() {
      await this.resetData();
      await this.getData();
    },
    // 获取数据
    async getData() {
      // 修改参数的时候 重置数据
      let params = {};
      if (this.etype === 2) {
        params.areaCode = this.areaCode;
      } else if (this.etype === 4) {
        params.orgTenantId = this.orgCode;
      } else {
        params.schoolCode = this.schoolCode;
      }
      console.log("this.apiType,", this.apiType);
      await this.$store.dispatch(this.apiType, params).then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          let data = res.data[0];
          Object.keys(this.sumNum).forEach((key) => {
            this.sumNum[key] = data[key];
          });
          this.titleItem = this.formateTitle();
          this.show = true;
          this.$emit("getCreateTime", data.createTime);
        }
      });
    },
    formateTitle() {
      let data = [
        {
          title: this.$t("el.board.NumberTeachers"),
          number: {
            number: [this.sumNum.teacherNum],
            textAlign: "left",
            content: "{nt}",
            formatter: this.formatter,
            style: {
              fontSize: 26,
              fontWeight: 400,
              fontFamily: "DIN",
              fill: "#04FFFF",
            },
          },
        },
        {
          title: this.$t("el.board.NumberStudents"),
          number: {
            number: [this.sumNum.studentNum],
            textAlign: "left",
            content: "{nt}",
            formatter: this.formatter,
            style: {
              fontSize: 26,
              fontWeight: 400,
              fontFamily: "DIN",
              fill: "#04FFFF",
            },
          },
        },
        {
          title: this.$t("el.board.TotalCourses"),
          number: {
            number: [this.sumNum.courseNum],
            textAlign: "left",
            content: "{nt}",
            formatter: this.formatter,
            style: {
              fontSize: 26,
              fontWeight: 400,
              fontFamily: "DIN",
              fill: "#04FFFF",
            },
          },
        },
        {
          title: this.$t("el.board.TotalLessonPeriods"),
          number: {
            number: [this.sumNum.lessonNum],
            textAlign: "left",
            content: "{nt}",
            formatter: this.formatter,
            style: {
              fontSize: 26,
              fontWeight: 400,
              fontFamily: "DIN",
              fill: "#04FFFF",
            },
          },
        },
        {
          title: this.$t("el.board.TotalLessons"),
          number: {
            number: [this.sumNum.lessonItemNum],
            textAlign: "left",
            content: "{nt}",
            formatter: this.formatter,
            style: {
              fontSize: 26,
              fontWeight: 400,
              fontFamily: "DIN",
              fill: "#04FFFF",
            },
          },
        },
      ];
      if ([1, 4].indexOf(this.etype) > -1) {
        data.unshift(
          {
            title: this.$t("el.board.AreaNumber"),
            number: {
              number: [this.sumNum.areaNum],
              textAlign: "left",
              content: "{nt}",
              formatter: this.formatter,
              style: {
                fontSize: 26,
                fontWeight: 400,
                fontFamily: "DIN",
                fill: "#04FFFF",
              },
            },
          },
          {
            title: this.$t("el.board.SchoolNumber"),
            number: {
              number: [this.sumNum.schoolNum],
              textAlign: "left",
              content: "{nt}",
              formatter: this.formatter,
              style: {
                fontSize: 26,
                fontWeight: 400,
                fontFamily: "DIN",
                fill: "#04FFFF",
              },
            },
          }
        );
      } else if (this.etype === 2) {
        data.unshift({
          title: this.$t("el.board.SchoolNumber"),
          number: {
            number: [this.sumNum.schoolNum],
            textAlign: "left",
            content: "{nt}",
            formatter: this.formatter,
            style: {
              fontSize: 26,
              fontWeight: 400,
              fontFamily: "DIN",
              fill: "#04FFFF",
            },
          },
        });
      }
      return data;
    },
    async resetData() {
      this.sumNum = this.$options.data().sumNum;
    },
  },
};
</script>

<style lang="less" scoped>
#center {
  display: flex;
  flex-direction: column;
  padding: 5px 50px;
  margin-top: 10px;
  .up {
    width: 100%;
    height: 180px;
    display: flex;
    flex-wrap: wrap;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 33.3%;
      height: 70px;
      .dv-dig-flop {
        width: 150px;
        height: 30px;
        margin-top: 14px;
      }
    }
    /deep/ .icon-component-tooltip {
      width: 200px;
      top: 20px;
      left: -20px;
    }
  }
  .s-up {
    .item {
      width: 25%;
    }
  }
  .fw-b {
    font-size: 16px;
    font-weight: 400;
    color: #99c0d8;
    display: flex;
    align-items: center;
    /deep/ .icon-component {
      display: flex;
      align-items: center;
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
