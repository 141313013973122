<template>
  <div id="topLeft">
    <div class="d-flex flex-title">
      <span class="fs-xl text"
        >{{ $t("el.board.usage")
        }}<hover-component
          style="text-align: left"
          :content="content"
          name="question-circle"
        ></hover-component
      ></span>
    </div>
    <dv-border-box-8 :color="['#3678C0']" :dur="0">
      <div class="bg-color-black">
        <div class="d-flex ai-center flex-column body-box">
          <!-- <dv-capsule-chart class="dv-cap-chart" :config="config" /> -->
          <topLeft
            :cdata="cdata"
            v-loading="loading"
            element-loading-background="rgba(0,0,0,.2)"
          />
        </div>
      </div>
    </dv-border-box-8>
  </div>
</template>

<script>
import topLeft from "@/components/echart/topLeft";
import { usageData } from "@/api/screen";
export default {
  components: {
    topLeft,
  },
  props: {
    etype: {
      type: Number,
      default: -1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    orgCode: {
      type: [String, Number],
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
  },
  watch: {
    sumDate(val) {
      if (val) {
        this.getData();
      }
    },
  },
  computed: {
    sumDate() {
      return this.startDate + this.endDate;
    },
    trueCode() {
      if (this.etype === 2) {
        return this.areaCode;
      } else if (this.etype === 3) {
        return this.schoolCode;
      } else if (this.etype === 4) {
        return this.orgCode;
      } else {
        return "";
      }
    },
    content() {
      switch (this.etype) {
        case 1:
        case 4:
          return `<p>${this.$t("el.board.UsageTrendChart")}</p><p>${this.$t(
            "el.board.PlatformUsageDesc"
          )}</p><p>${this.$t(
            "el.board.PreparationUsageRateDesc"
          )}</p><p>${this.$t("el.board.TeachingUsageRateDesc")}</p>`;
        case 2:
          return `<p>${this.$t("el.board.UsageTrendChart")}</p><p>${this.$t(
            "el.board.UsageRateAreaDesc"
          )}</p><p>${this.$t(
            "el.board.PreparationUsageRateAreaDesc"
          )}</p><p>${this.$t("el.board.TeachingUsageRateAreaDesc")}</p>`;
        case 3:
          return `<p>${this.$t("el.board.UsageTrendChart")}</p><p>${this.$t(
            "el.board.UsageRateDesc"
          )}</p><p>${this.$t("el.board.PreparationRateDesc")}</p><p>${this.$t(
            "el.board.TeachingUsageRateSchoolDesc"
          )}</p>`;
        default:
          return "";
      }
    },
  },
  data() {
    return {
      loading: true,
      cdata: {
        indicatorData: {
          bkData: [],
          skData: [],
          ptData: [],
        },
        Date: [],
      },
    };
  },
  methods: {
    // 获取折线图数据
    async getData() {
      this.loading = true;
      this.cdata = {
        indicatorData: {
          bkData: [],
          skData: [],
          ptData: [],
        },
        Date: [],
      };
      let params = {
        // kanbanType: this.etype,
        kanbanType: this.etype,
        code: this.trueCode,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      await usageData(params)
        .then((res) => {
          let data = res.data;
          // let dateData = data.map((item) => {
          //   return item.statisticDate;
          // });
          if (
            data[0].statisticDate.substring(0, 4) ==
            data[data.length - 1].statisticDate.substring(0, 4)
          ) {
            this.cdata.Date = data.map((item) => {
              return item.statisticDate.substr(5);
            });
          } else {
            this.cdata.Date = data.map((item) => {
              return item.statisticDate;
            });
          }
          this.cdata.indicatorData.bkData = data.map((item) => {
            return { value: item.bkUsedRate, count: item.bkUserCount };
          });
          this.cdata.indicatorData.skData = data.map((item) => {
            return { value: item.skUsedRate, count: item.skUserCount };
          });
          this.cdata.indicatorData.ptData = data.map((item) => {
            return { value: item.usedRate, count: item.userCount };
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// $box-height: 305px;
// $box-width: 100%;
#topLeft {
  $box-height: 305px;
  $box-width: 100%;
  padding: 5px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
  .bg-color-black {
    // padding: 5px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
    // padding-top: 30px;
  }
  .flex-title {
    // text-align:center;
    // z-index: 999;
    // position: absolute;
    // top: -5px;
    // left: calc(50% - 60px);
    // background: #1b2a5c;
    // padding: 10px 20px;
    // height: 25px;
    line-height: 25px;
    text-align: center;
    z-index: 999;
    position: absolute;
    top: -6px;
    left: calc(50% - 60px);
    background: #162a5f;
    padding: 0 20px;
    .text {
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      color: #04ffff;
      font-weight: 500;
      word-break: break-all;
    }
    ::v-deep .icon-component-tooltip {
      right: -210px;
    }
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
}
</style>
