<template>
  <div style="width: 100%">
    <Echart
      ref="cEchart"
      :options="options"
      id="centreRight2Chart1"
      height="290px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        // 固定样式数据
        let lineStyle = {
          normal: {
            width: 1,
            opacity: 0.5,
          },
        };

        console.log(this.$refs.cEchart);

        this.options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
            formatter: function (params) {
              var result = params[0].name || "";
              params.forEach(function (item) {
                // console.log("item", item);
                let spanHtml = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:8px;height:8px;background-color:${item.color}"></span>`;
                result += "</br>" + spanHtml + item.seriesName + item.data;
              });
              return result;
            },
          },
          barWidth: 12,
          legend: {
            show: true, // 是否显示
            type: "plain", // 图例的类型 'plain':普通图例  'scroll':可滚动翻页的图例
            zlevel: 1, // 所有图形的 zlevel 值。
            align: "left",
            top: "12%", // bottom:"20%" // 组件离容器的距离
            left: "center", // left:"10%"  // // 组件离容器的距离
            orient: "horizontal", // 图例列表的布局朝向。 'horizontal'  'vertical'
            itemGap: 34,
            padding: [0, 0, 0, 0],
            itemWidth: 8,
            itemHeight: 8,
            icon: "circle",
            textStyle: {
              padding: [0, 0, 0, 5],
              color: "#7C8695",
              fontWeight: 400,
            },
          },
          grid: {
            left: "3%",
            right: "6%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#3678C0",
                width: 1,
              },
            },
            axisLabel: {
              textStyle: {
                color: "#04FFFF",
              },
            },
          },
          yAxis: {
            type: "category",
            data: newData.cityData,
            axisLabel: {
              formatter: function (params) {
                var val = "";
                if (params.length > 6) {
                  val = params.substr(0, 6) + "...";
                  return val;
                } else {
                  return params;
                }
              },
              margin: 30,
              textStyle: {
                color: "#04FFFF", // 坐标值得具体的颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#3678C0",
                width: 1,
              },
            },
          },
          series: [
            {
              name: this.$t("el.dataAnalysis.preparationTime"),
              type: "bar",
              stack: "total",
              color: "#566EC2",
              emphasis: {
                focus: "series",
              },
              data: newData.indicatorData.bkData,
            },
            {
              name: this.$t("el.board.DurationTeaching"),
              type: "bar",
              stack: "total",
              color: "#62C1D0",
              emphasis: {
                focus: "series",
              },
              data: newData.indicatorData.skData,
              barGap: "10%" /* 多个并排柱子设置柱子之间的间距*/,
              barCategoryGap: "15%" /* 多个并排柱子设置柱子之间的间距*/,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
