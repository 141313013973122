<template>
  <div style="width: 100%">
    <Echart
      :options="options"
      id="topLeft"
      height="300px"
      width="100%"
      v-if="show"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import { toFixed5 } from "@/utils";
export default {
  data() {
    return {
      options: {},
      show: false,
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.show = false;
        // let myChart1  =  echarts.init(document.getElementById("topLeft"))
        // 固定样式数据
        let lineStyle = {
          normal: {
            width: 1,
            opacity: 0.5,
          },
        };

        this.options = {
          tooltip: {
            trigger: "axis",
            // formatter: function(value) {return value*100+'%'},
            formatter: function (seriesData, ticket, callback) {
              let showHtml = "";
              let top = "";
              seriesData.forEach((item, i) => {
                let icon = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:8px;height:8px;background-color:${item.color}"></span>`;
                let name = item.seriesName;
                let Xname = item.name;
                let Yname = item.data.value;
                let count = item.data.count;
                // let marker = item.marker;
                top = Xname;
                showHtml =
                  showHtml +
                  "</br>" +
                  icon +
                  name +
                  "：" +
                  // Number(Number(Yname * 100).toFixed(1))
                  toFixed5(Yname * 100, 1) +
                  "%" +
                  " " +
                  count +
                  "人";
              });
              return top + showHtml;
            },
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            orient: "horizontal",
            left: "center",
            top: "24px",
            itemGap: 34,
            padding: [0, 0, 0, 0],
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              padding: [0, 0, 0, 5],
              color: "#7C8695",
              fontWeight: 400,
            },
            data: [
              { name: this.$t("el.board.UsageRate"), icon: "circle" },
              {
                name: this.$t("el.dataAnalysis.preparingUsage"),
                icon: "circle",
              },
              {
                name: this.$t("el.dataAnalysis.teachingUtilization"),
                icon: "circle",
              },
            ],
          },
          xAxis: {
            type: "category",
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#04FFFF",
              },
            },
            boundaryGap: true,
            data: this.cdata.Date,
          },
          yAxis: {
            type: "value",
            max: 1,
            min: 0,
            splitNumber: 5,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,.2)",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#04FFFF",
              },
              // internal:'auto',
              formatter: function (value) {
                return value * 100 + "%";
              },
            },
          },
          dataZoom: [
            {
              show: this.cdata.Date.length > 5, // 是否显示
              backgroundColor: "rgba(100,172,212,.1)",
              type: "slider",
              height: "20",
              dataBackground: {
                lineStyle: {
                  color: "rgba(14, 219, 219, 0.25)",
                },
                areaStyle: { color: "rgba(14, 219, 219, 0.25)" },
              },
              fillerColor: "rgba(14,219,219,0.2)",
              filterMode: "empty",
              startValue: this.cdata.Date[0],
              endValue: this.cdata.Date[4],
            },
          ],
          series: [
            {
              name: this.$t("el.board.UsageRate"),
              type: "line",
              smooth: false,
              symbol: "circle",
              color: ["#F8B62B"],
              data: this.cdata.indicatorData.ptData,
            },
            {
              name: this.$t("el.dataAnalysis.preparingUsage"),
              type: "line",
              color: ["#EA5449"],
              smooth: false,
              symbol: "circle",
              data: this.cdata.indicatorData.bkData,
            },
            {
              name: this.$t("el.dataAnalysis.teachingUtilization"),
              type: "line",
              color: ["#50ACFE"],
              smooth: false,
              symbol: "circle",
              data: this.cdata.indicatorData.skData,
            },
          ],
        };
        this.$nextTick(() => {
          this.show = true;
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
