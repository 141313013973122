<template>
  <div id="middleBottom">
    <div class="d-flex flex-title">
      <div class="fs-xl text">{{ title }}</div>
    </div>
    <dv-border-box-8
      :color="['#3678C0']"
      :dur="0"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.2)"
    >
      <span @click="showDialog = true" class="charts-more">{{
        $t("el.board.More")
      }}</span>
      <div class="bg-color-black">
        <empty-chart v-if="isEmpty"></empty-chart>
        <div class="d-flex ai-center flex-column body-box">
          <dv-scroll-board
            :config="config"
            style="
              width: 90%;
              height: 240px;
              color: #abd6f0;
              text-align: center;
            "
            v-if="config.data.length > 0"
          />
        </div>
      </div>
    </dv-border-box-8>
    <dialog-component @closeDialog="showDialog = false" v-if="showDialog">
      <template slot="header">
        <p class="d-title">{{ title }}</p>
      </template>
      <template slot="xm-table">
        <xm-table
          ref="xmTable"
          :fatch-method="fatchMethod"
          :form-items="formItems"
          :columns="columns"
          :formOptions="{
            hiddenDefaultFormBtn: true,
          }"
          :tableOptions="{
            stripe: true,
            border: false,
          }"
          :class="computedClassName"
          :showPagination="isShowPagination"
          :sreenEmpty="true"
          :defaultLimit="5"
          :autoLoad="true"
        >
          <template slot="formBtn">
            <el-button
              type="primary"
              @click="query()"
              size="small"
              class="teachManage-tool-btnicon"
              >{{ $t("el.common.search") }}</el-button
            >
          </template>
        </xm-table>
      </template>
      <template slot="footer">
        <div v-show="isShowPagination" class="f-button">
          <a target="_blank" style="color: #04ffff">
            <el-button
              :loading="exportLoading"
              type="primary"
              size="small"
              @click="exportFile"
            >
              <span class="button-text">{{ $t("el.common.export") }}</span>
            </el-button>
          </a>
        </div>
      </template>
    </dialog-component>
  </div>
</template>

<script>
import dialogComponent from "@/components/echart/dialog/index";
import emptyChart from "@/components/echart/empty";
import {
  ptParticipationData,
  exportPtParticipationData,
  jgParticipationData,
  exportJgParticipationData,
  areaParticipationData,
  exportAreaParticipationData,
  schoolParticipationData,
  exportSchoolParticipationData,
} from "@/api/screen";
import { handparams } from "../../../utils";

export default {
  props: {
    etype: {
      type: Number,
      default: -1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    orgCode: {
      type: [String, Number],
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
  },
  components: {
    dialogComponent,
    emptyChart,
  },
  mounted() {
    // this.boardType=2;
  },
  data() {
    return {
      noDataImg: require("@/assets/images/no-data.png"),
      boardType: 3,
      loading: true,
      isEmpty: false,
      content: "",
      showDialog: false,
      config: {
        header: [],
        headerBGC: "rgba(0,0,0,0)",
        align: "center",
        evenRowBGC: "rgba(100,172,212,0.1)",
        oddRowBGC: "rgba(0,0,0,0)",
        data: [],
      },
      isShowPagination: true,
      exportLoading: false,
    };
  },
  watch: {
    sumDate(val) {
      if (val) {
        this.getData();
      }
    },
  },
  computed: {
    ptHeader() {
      return [
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.AreaDataName"
        )}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.NumberPrticipatingSchools"
        )}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.NumberTeachers"
        )}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.NumberStudents"
        )}</span>`,
      ];
    },

    areaHeader() {
      return [
        `<span style='color:#04FFFF'>${this.$t("el.board.SchoolName")}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.NumberTeachers"
        )}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.NumberStudents"
        )}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.TotalCourses"
        )}</span>`,
      ];
    },
    schoolHeader() {
      return [
        `<span style='color:#04FFFF'>${this.$t("el.board.grade")}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.NumberPrticipatingClasses"
        )}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.NumberStudents"
        )}</span>`,
        `<span style='color:#04FFFF'>${this.$t(
          "el.board.StudentParticipationRate"
        )}</span>`,
      ];
    },

    // 根据类型 展示不同名称
    title() {
      switch (this.etype) {
        case 1:
        case 4:
          return this.$t("el.board.ParticipationArea");
        case 2:
          return this.$t("el.board.ParticipationSchool");
        case 3:
          return this.$t("el.board.ParticipationGrade");
        default:
          return "";
      }
      // return type;
    },
    sumDate() {
      return this.startDate + this.endDate;
    },
    trueCode() {
      if (this.etype === 2) {
        return this.areaCode;
      } else if (this.etype === 3) {
        return this.schoolCode;
      } else if (this.etype === 4) {
        return this.orgCode;
      } else {
        return "";
      }
    },
    formItems() {
      switch (this.etype) {
        case 1:
        case 4:
          return [
            {
              itemType: "input",
              prop: "areaName",
              placeholder: this.$t("el.common.pleaseEnter"),
              label: this.$t("el.board.AreaDataName"),

              itemStyle: "width:142px;height:32px;",
              // labelWidth: "84px",
              clearable: true,
            },
          ];
        case 2:
          return [
            {
              itemType: "input",
              prop: "schoolName",
              placeholder: this.$t("el.common.pleaseEnter"),
              label: this.$t("el.board.SchoolName"),
              itemStyle: "width:142px;height:32px;",
              // labelWidth: "84px",
              clearable: true,
            },
          ];
        case 3:
          return [
            {
              itemType: "input",
              prop: "gradeName",
              placeholder: this.$t("el.common.pleaseEnter"),
              label: this.$t("el.infrastructure.gradeName"),
              itemStyle: "width:142px;height:32px;",
              // labelWidth: "84px",
              clearable: true,
            },
          ];
        default:
          return [];
      }
    },
    columns() {
      switch (this.etype) {
        case 1:
        case 4:
          return [
            {
              label: this.$t("el.board.AreaDataName"),
              prop: "areaName",
            },
            {
              label: this.$t("el.board.NumberPrticipatingSchools"),
              prop: "schoolNum",
            },
            {
              label: this.$t("el.board.NumberTeachers"),
              prop: "teacherNum",
            },
            {
              label: this.$t("el.board.NumberStudents"),
              prop: "studentNum",
            },
          ];
        case 2:
          return [
            {
              label: this.$t("el.board.SchoolName"),
              prop: "schoolName",
            },
            {
              label: this.$t("el.board.NumberTeachers"),
              prop: "teacherNum",
            },
            {
              label: this.$t("el.board.NumberStudents"),
              prop: "studentNum",
            },
            {
              label: this.$t("el.board.TotalCourses"),
              prop: "courseNum",
            },
          ];
        case 3:
          return [
            {
              label: this.$t("el.schoolResourceManage.grade"),
              prop: "gradeName",
            },
            {
              label: this.$t("el.board.NumberPrticipatingClasses"),
              prop: "classNum",
            },
            {
              label: this.$t("el.board.NumberStudents"),
              prop: "studentNum",
            },
            {
              label: this.$t("el.board.ParticipationRate"),
              prop: "partRate",
            },
          ];
        default:
          return [];
      }
    },
    computedClassName() {
      if (!this.isShowPagination) {
        return "empty-data";
      } else if (this.etype === 4) {
        return "jg-table-data";
      } else {
        return "";
      }
    },
  },
  created() {
    // this.boardType = 3;
  },
  methods: {
    // 获取轮播表数据
    async getData() {
      this.loading = true;
      let params = {
        // code:101101101,
        startDate: this.startDate,
        endDate: this.endDate,
        limit: 999,
        offset: 0,
      };
      switch (this.etype) {
        case 1:
        case 4:
          try {
            let res;
            if (this.etype === 1) {
              res = await ptParticipationData(handparams(params));
            } else {
              params.orgTenantId = this.orgCode;
              res = await jgParticipationData(handparams(params));
            }
            let ptParams = [];
            let dataArr = res.data.records;
            if (dataArr.length === 0) {
              this.isEmpty = true;
            } else {
              this.isEmpty = false;
              dataArr.forEach((item) => {
                ptParams.push([
                  '<span data-title="' +
                    item.areaName +
                    '"class=' +
                    (item.areaName.length > 9 ? "xy-title" : "") +
                    ">" +
                    item.areaName +
                    "</span>",
                  item.schoolNum,
                  item.teacherNum,
                  item.studentNum,
                ]);
              });
            }
            this.config = {
              header: this.ptHeader,
              headerBGC: "rgba(0,0,0,0)",
              align: "center",
              evenRowBGC: "rgba(100,172,212,0.1)",
              oddRowBGC: "rgba(0,0,0,0)",
              data: ptParams,
            };
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
          break;
        case 2:
          params.areaTenantId = this.areaCode;
          await areaParticipationData(handparams(params)).then((res) => {
            let areaParams = [];
            let areaArr = res.data.records;
            if (areaArr.length === 0) {
              this.isEmpty = true;
            } else {
              this.isEmpty = false;
              areaArr.forEach((item) => {
                areaParams.push([
                  '<span data-title="' +
                    item.schoolName +
                    '"class=' +
                    (item.schoolName.length > 9 ? "xy-title" : "") +
                    ">" +
                    item.schoolName +
                    "</span>",
                  // item.schoolName,
                  item.teacherNum,
                  item.studentNum,
                  item.courseNum,
                ]);
              });
            }
            this.config = {
              header: this.areaHeader,
              headerBGC: "rgba(0,0,0,0)",
              align: "center",
              evenRowBGC: "rgba(100,172,212,0.1)",
              oddRowBGC: "rgba(0,0,0,0)",
              data: areaParams,
            };
            this.loading = false;
          });
          break;
        case 3:
          params.tenantId = this.schoolCode;
          await schoolParticipationData(handparams(params)).then((res) => {
            let schoolParams = [];
            let schoolArr = res.data.records;
            if (schoolArr.length === 0) {
              this.isEmpty = true;
            } else {
              this.isEmpty = false;
              schoolArr.forEach((item) => {
                schoolParams.push([
                  '<span data-title="' +
                    item.gradeName +
                    '"class=' +
                    (item.gradeName.length > 9 ? "xy-title" : "") +
                    ">" +
                    item.gradeName +
                    "</span>",
                  // item.gradeName,
                  item.classNum,
                  item.studentNum,
                  item.partRate,
                ]);
              });
            }
            this.config = {
              header: this.schoolHeader,
              headerBGC: "rgba(0,0,0,0)",
              align: "center",
              evenRowBGC: "rgba(100,172,212,0.1)",
              oddRowBGC: "rgba(0,0,0,0)",
              data: schoolParams,
            };
            this.loading = false;
          });
          break;
      }
    },
    // 获取表格数据
    async fatchMethod(params) {
      let form, areaForm, schoolForm;
      if (this.etype === 1 || this.etype === 4) {
        try {
          form = {
            offset: params.offset,
            limit: params.limit,
            areaName: params.areaName,
            startDate: this.startDate,
            endDate: this.endDate,
          };
          let res;
          if (this.etype === 1) {
            res = await ptParticipationData(handparams(form));
          } else {
            form.orgTenantId = this.orgCode;
            res = await jgParticipationData(handparams(form));
          }
          if (res.data.records.length === 0) {
            this.isShowPagination = false;
          } else {
            this.isShowPagination = true;
          }
          return res;
        } catch {
          this.isShowPagination = false;
        }
      } else if (this.etype === 2) {
        areaForm = {
          offset: params.offset,
          limit: params.limit,
          areaTenantId: this.areaCode,
          startDate: this.startDate,
          endDate: this.endDate,
          schoolName: params.schoolName,
        };
        return areaParticipationData(handparams(areaForm)).then((res) => {
          if (res.data.records.length === 0) {
            this.isShowPagination = false;
          } else {
            this.isShowPagination = true;
          }
          return res;
        });
      } else if (this.etype === 3) {
        schoolForm = {
          offset: params.offset,
          limit: params.limit,
          tenantId: this.schoolCode,
          gradeName: params.gradeName,
          startDate: this.startDate,
          endDate: this.endDate,
        };
        return schoolParticipationData(handparams(schoolForm)).then((res) => {
          if (res.data.records.length === 0) {
            this.isShowPagination = false;
          } else {
            this.isShowPagination = true;
          }
          return res;
        });
      }
    },
    query() {
      this.$refs["xmTable"].query();
    },
    // exportFile() {
    //   console.log(this.$refs["xmTable"].getQueryParams());
    //   let paramsName = this.$refs["xmTable"].getQueryParams().courseName || "";
    //   let gradeId = this.$refs["xmTable"].getQueryParams().gradeId || "";
    //   let subjectId = this.$refs["xmTable"].getQueryParams().subjectId || "";
    //   let startDate = encodeURI(this.startDate);
    //   let endDate = encodeURI(this.endDate);
    //   let url = `/bskpt-front/heat/exportCourseHeat?kanbanType=${this.etype}&startDate=${startDate}&endDate=${endDate}&code=${this.trueCode}&courseName=${paramsName}&gradeId=${gradeId}&subjectId=${subjectId}`;
    //   let a = document.createElement("a");
    //   a.setAttribute("href", url);
    //   a.click();
    // },
    async exportFile() {
      this.exportLoading = true;
      let params = this.$refs["xmTable"].getQueryParams();
      let form = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      switch (this.etype) {
        case 1:
        case 4:
          form.areaName = params.areaName;
          try {
            let res;
            if (this.etype === 1) {
              res = await exportPtParticipationData(handparams(form));
            } else {
              form.orgTenantId = this.orgCode;
              res = await exportJgParticipationData(handparams(form));
            }
            console.log(res, "res====");
            let url = window.URL.createObjectURL(res);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute(
              "download",
              form.startDate.substring(0, 10) +
                this.$t("el.common.to") +
                form.endDate.substring(0, 10) +
                this.$t("el.board.ParticipationArea") +
                ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (error) {
            console.log(error);
          } finally {
            this.exportLoading = false;
          }
          break;
        case 2:
          form.areaTenantId = this.areaCode;
          form.schoolName = params.schoolName;
          console.log(form, 87778);
          exportAreaParticipationData(form)
            .then((res) => {
              let url = window.URL.createObjectURL(res);
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              console.log(url, "url=====");
              link.setAttribute(
                "download",
                form.startDate.substring(0, 10) +
                  this.$t("el.common.to") +
                  form.endDate.substring(0, 10) +
                  this.$t("el.board.ParticipationSchool") +
                  ".xlsx"
              );
              console.log("link====", link);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch((err) => {
              console.error("捕获到错误：", err);
              if (err.response) {
                console.error("响应数据：", err.response.data);
                console.error("响应状态码：", err.response.status);
              }
            })
            .finally(() => {
              this.exportLoading = false;
            });
          break;
        case 3:
          form.tenantId = this.schoolCode;
          form.gradeName = params.gradeName;
          exportSchoolParticipationData(handparams(form))
            .then((res) => {
              let url = window.URL.createObjectURL(res);
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.setAttribute(
                "download",
                form.startDate.substring(0, 10) +
                  this.$t("el.common.to") +
                  form.endDate.substring(0, 10) +
                  this.$t("el.board.ParticipationGrade") +
                  ".xlsx"
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .finally(() => {
              this.exportLoading = false;
            });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// $box-height: 305px;
// $box-width: 100%;
#middleBottom {
  $box-height: 305px;
  $box-width: 100%;
  padding: 5px 40px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
  ::v-deep .xy-title {
    &::before {
      content: attr(data-title);
      display: none;
      padding: 0 10px;
      left: 60px;
      margin-top: -30px;
      position: absolute;
      z-index: 999;
      color: #fff;
      border-radius: 10px;
      background-color: rgba(50, 50, 50, 0.7);
    }
  }
  ::v-deep .xy-title:hover:before {
    display: block;
  }
  .bg-color-black {
    // padding: 5px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
    // padding-top: 30px;
  }
  .flex-title {
    // text-align:center;
    // z-index: 999;
    // position: absolute;
    // top: -5px;
    // left: calc(50% - 60px);
    // background: #1b2a5c;
    // padding: 10px 20px;
    // height: 25px;
    line-height: 25px;
    text-align: center;
    z-index: 999;
    position: absolute;
    top: -4px;
    left: calc(50% - 80px);
    background: #162a5f;
    padding: 0 20px;
    .text {
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      color: #04ffff;
      font-weight: 500;
      word-break: break-all;
    }
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    padding-top: 40px;
    border-radius: 10px;
    overflow: hidden;
    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
  .charts-more {
    position: absolute;
    right: 25px;
    top: 20px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    z-index: 99;
  }
  .no-data-img {
    width: 200px;
  }
  .f-button {
    ::v-deep .el-button {
      > span {
        width: auto !important;
      }
    }
  }
}
::v-deep .jg-table-data {
  .teachManage-page {
    display: none;
  }
  .el-table__body-wrapper {
    height: 230px;
    overflow-y: auto;
  }
}
</style>
