<template>
  <board-dialog
    ref="changeBoardDialog"
    :formItems="formDialogItems"
    :formOptions="dialogFormOptions"
    :visible="showChangeBoard"
    :optionsData="optionsData"
    @close-dialog="closeDialog"
    @submit-form="submitDialogForm"
  >
    <template slot="dialogContent">
      <div v-loading="!loadBoardOptionData">
        <el-radio-group @change="onBoardTypeChange" v-model="radio">
          <el-radio
            v-for="item in optionsData.boardOptionsList"
            :key="item.value"
            :label="item.value"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
        <div v-if="radio === 2 && [1, 4].indexOf(realTenentType) > -1">
          <board-select
            :formData="formDataArea"
            :item="selectOptionItem"
            @onSelectChange="onAreaChange"
          ></board-select>
        </div>
        <div v-else-if="radio === 3">
          <board-select
            :formData="formData"
            :item="selectSchoolOptionItem"
            @onSelectChange="onSchoolSelectChange"
          ></board-select>
        </div>
      </div>
    </template>
  </board-dialog>
</template>

<script>
import BoardDialog from "../BoardDialog/index.vue";
import BoardSelect from "../BoardSelect/index.vue";
// import BoardCascader from "../BoardCascader/index.vue";
// import AntBoardCascader from "../AntBoardCascader/index.vue";
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogItems: [],
      formOptions: {},
      radio: 1,
      boardAddressList: [],
      formData: {},
      formDataArea: {},
    };
  },
  components: {
    BoardDialog,
    BoardSelect,
    // BoardCascader,
    // AntBoardCascader,
  },
  props: {
    showChangeBoard: {
      type: Boolean,
      default: false,
    },
    optionsData: {
      type: Object,
      default: () => {
        return {
          boardOptionsList: [],
          boardAreaList: [],
          boardAddressList: [],
        };
      },
    },
    realTenentType: {
      type: Number,
      default: 1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    etype: {
      type: Number,
      default: 1,
    },
    loadBoardOptionData: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.radio = this.etype;
    console.log("this.radio", this.radio);
  },

  watch: {
    etype(val) {
      this.radio = val;
    },
    optionsData: {
      handler(val, old) {
        console.log("optionsData.boardAddressList", val);
        // 初始化数据
        console.log(
          this.optionsData.boardAddressList,
          "this.optionsData.boardAddressList"
        );
        if (
          Array.isArray(this.optionsData.boardAreaList) &&
          this.optionsData.boardAreaList.length > 0
        ) {
          this.$set(
            this.formDataArea,
            "areaCodeList",
            this.etype === 2
              ? this.areaCode
              : this.optionsData.boardAreaList[0].id
          );
          this.$set(
            this.formDataArea,
            "areaName",
            this.optionsData.boardAreaList[0].name
          );
        }
        if (
          Array.isArray(this.optionsData.boardAddressList) &&
          this.optionsData.boardAddressList.length > 0
        ) {
          this.$set(
            this.formData,
            "schoolCode",
            this.etype === 3
              ? this.schoolCode
              : this.optionsData.boardAddressList[0].id
          );
          this.$set(
            this.formData,
            "schoolName",
            this.optionsData.boardAddressList[0].name
          );
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
      areaSchoolList: (state) => state.board.areaSchoolList,
    }),
    ...mapGetters(["lookBoardInfo"]),
    // dialog 表单
    formDialogItems() {
      return this.dialogItems;
    },
    dialogFormOptions() {
      return this.formOptions;
    },
    selectOptionItem() {
      return {
        prop: "areaCodeList",
        options: this.optionsData.boardAreaList,
        "popper-class": "popper-class-select",
        itemStyle: "width:100%;",
        "popper-append-to-body": false,
        filterable: true,
      };
    },
    selectSchoolAreaItem() {
      return {
        prop: "schoolAreaCodeList",
        options: this.optionsData.boardAreaList,
        "popper-class": "popper-class-select",
        itemStyle: "width: 145px;",
        "popper-append-to-body": false,
        filterable: true,
      };
    },
    selectSchoolOptionItem() {
      return {
        prop: "schoolCode",
        itemStyle: "width:100%",
        options: this.optionsData.boardAddressList,
        "popper-class": "popper-class-select",
        // ["popper__arrow"]: "popper__arrow",
        "popper-append-to-body": false,
        filterable: true,
      };
    },
  },
  methods: {
    ...mapMutations(["SET_LOOKBOARD_INFO"]),
    ...mapActions(["getAreaSchoolList"]),
    // 关闭 弹框
    closeDialog() {
      this.radio = this.etype;
      this.formData = {};
      this.formDataArea = {};
      this.$emit("close-dialog");
    },
    // 获取选中的 区域
    getChoseItem(areaCode, list) {
      let result = this.treeFindPath(
        list,
        (node) => node.addressCode === areaCode
      );
      console.log("result:", result);
      return result;
    },

    treeFindPath(tree, func, path = []) {
      if (!tree) return [];
      for (const data of tree) {
        path.push(data.addressCode);
        if (func(data)) return path;
        if (data.children) {
          const findChildren = this.treeFindPath(data.children, func, path);
          if (findChildren.length) return findChildren;
        }
        path.pop();
      }
      return [];
    },

    findDefaultItem(areaCodeList, list) {
      let areaList = [];
      this.getArrayTree(list, areaList);
      if (areaCodeList && areaCodeList.length > 0) {
        let item = areaList.find(
          (item) => item.addressCode === areaCodeList[areaCodeList.length - 1]
        );
        if (item) {
          return item;
        }
      }
      return areaList[0] || null;

      // function newFunction(list) {
      //   list.forEach((element) => {
      //     if (element.children && element.children.length > 0) {
      //       newFunction(element.children);
      //     } else {
      //       areaList.push(element);
      //     }
      //   });
      // }
    },
    getArrayTree(list, areaList) {
      list.forEach((element) => {
        if (element.children && element.children.length > 0) {
          this.getArrayTree(element.children, areaList);
        } else {
          areaList.push(element);
        }
      });
    },
    // 设置下拉框默认选中值
    setNewSelectValue(type) {
      if (type === 2) {
        if (this.optionsData.boardAreaList.length > 0) {
          this.$set(
            this.formDataArea,
            "areaCodeList",
            this.optionsData.boardAreaList[0].id
          );
          this.$set(
            this.formDataArea,
            "areaName",
            this.optionsData.boardAreaList[0].name
          );
        }
      } else if (type === 3) {
        if (this.optionsData.boardAddressList.length > 0) {
          this.$set(
            this.formData,
            "schoolCode",
            this.optionsData.boardAddressList[0].id
          );
          this.$set(
            this.formData,
            "schoolName",
            this.optionsData.boardAddressList[0].name
          );
        }
      }
    },
    // 设置默认选中看板选项
    async setSelectValue(areaCodeList, schoolCode) {
      // console.log("this.radio", this.radio);
      if (this.radio === 2) {
        let item = this.findDefaultItem(
          areaCodeList,
          this.optionsData.boardAreaList
        );

        // let item = this.optionsData.boardAreaList.find(
        //   (item) => item.addressCode === areaCode
        // );
        // console.log("item", item);
        // 初始化默认区域
        if (item) {
          this.$set(this.formDataArea, "areaCode", item.addressCode);
          this.$set(this.formDataArea, "areaName", item.addressName);

          if (areaCodeList && areaCodeList.length > 0) {
            this.$set(this.formDataArea, "areaCodeList", areaCodeList);
          } else {
            // let a = [];

            // a.push(this.optionsData.boardAreaList[0].addressCode);
            // a.push(this.optionsData.boardAreaList[0].children[0].addressCode);
            // a.push(
            //   this.optionsData.boardAreaList[0].children[0].children[0]
            //     .addressCode
            // );
            // a.push(
            //   this.optionsData.boardAreaList[0].children[0].children[0]
            //     .children[0].addressCode
            // );
            let b = [];
            this.getArrayTree(this.optionsData.boardAreaList, b);

            let a = this.treeFindPath(
              this.optionsData.boardAreaList,
              (node) => node.addressCode === b[0].addressCode
            );
            console.log("a", a);
            this.$set(this.formDataArea, "areaCodeList", a);
          }

          return;
        }
        this.$set(this.formDataArea, "areaCode", "");
        this.$set(this.formDataArea, "areaName", "");
      } else if (this.radio === 3) {
        // let item = this.optionsData.boardAddressList.find(
        //   (item) => item.addressCode === areaCode
        // );
        // console.log("areaCode", areaCode);

        let item = this.findDefaultItem(
          areaCodeList,
          this.optionsData.boardAddressList
        );

        // 判断默认选择的区域和学校
        if (item) {
          // item.areaSchoolList.forEach((element) => {
          //   element.value = element.schoolCode;
          //   element.label = element.schoolName;
          // });
          this.$set(this.formData, "schoolAreaCode", item.addressCode);
          this.$set(this.formData, "areaName", item.addressName);
          if (areaCodeList && areaCodeList.length > 0) {
            this.$set(this.formData, "schoolAreaCodeList", areaCodeList);
          } else {
            // let a = [];

            // a.push(this.optionsData.boardAddressList[0].addressCode);
            // a.push(
            //   this.optionsData.boardAddressList[0].children[0].addressCode
            // );
            // a.push(
            //   this.optionsData.boardAddressList[0].children[0].children[0]
            //     .addressCode
            // );
            // a.push(
            //   this.optionsData.boardAddressList[0].children[0].children[0]
            //     .children[0].addressCode
            // );
            let b = [];
            this.getArrayTree(this.optionsData.boardAddressList, b);

            let a = this.treeFindPath(
              this.optionsData.boardAddressList,
              (node) => node.addressCode === b[0].addressCode
            );
            console.log("a", a);
            this.$set(this.formData, "schoolAreaCodeList", a);
          }
          await this.getAreaSchoolList(item.addressCode);

          this.boardAddressList = this.areaSchoolList;

          if (this.boardAddressList.length > 0) {
            if (schoolCode) {
              let obj = this.boardAddressList.find(
                (item) => item.schoolCode === schoolCode
              );
              // console.log("obj", obj);
              if (obj) {
                this.$set(this.formData, "schoolCode", obj.schoolCode);
                this.$set(this.formData, "schoolName", obj.schoolName);
              } else {
                this.$set(
                  this.formData,
                  "schoolCode",
                  this.boardAddressList[0].schoolCode
                );
                this.$set(
                  this.formData,
                  "schoolName",
                  this.boardAddressList[0].schoolName
                );
              }
            } else {
              this.$set(
                this.formData,
                "schoolCode",
                this.boardAddressList[0].schoolCode
              );
              this.$set(
                this.formData,
                "schoolName",
                this.boardAddressList[0].schoolName
              );
            }
          } else {
            this.$set(this.formData, "schoolCode", "");
            this.$set(this.formData, "schoolName", "");
          }
        } else {
          this.$set(this.formData, "schoolAreaCode", "");
          this.$set(this.formData, "areaName", "");
          this.$set(this.formData, "schoolCode", "");
          this.$set(this.formData, "schoolName", "");
        }
      }
    },
    // 切换看板 radio
    onBoardTypeChange() {
      // 重置数据，默认选中第一个
      this.setNewSelectValue(this.radio);
    },
    // 只有区域选择
    onAreaChange(val) {
      // this.setSelectValue(val);
      this.$set(this.formDataArea, "areaCodeList", val);
      const name = this.optionsData.boardAreaList.find(
        (item) => item.id === val
      ).name;
      this.$set(this.formDataArea, "areaName", name);
    },
    // 学校选择
    onSchoolSelectChange(val) {
      this.$set(this.formData, "schoolCode", val);
      const name = this.optionsData.boardAddressList.find(
        (item) => item.id === val
      ).name;
      this.$set(this.formData, "schoolName", name);
    },
    // 确定
    submitDialogForm1() {
      console.log("formData", this.formData);
      if (this.radio === 2) {
        if (!this.formDataArea.areaCode) {
          this.$message.warning("请先选择区域！");
          return;
        }
      } else if (this.radio === 3) {
        if (!this.formData.schoolAreaCode) {
          this.$message.warning("请先选择区域！");
          return;
        }
        if (!this.formData.schoolCode) {
          this.$message.warning("请先选择学校！");
          return;
        }
      }
      if (this.radio === 2) {
        this.SET_LOOKBOARD_INFO({
          kanbanType: this.radio,
          ...this.formDataArea,
        });
      } else {
        this.SET_LOOKBOARD_INFO({
          kanbanType: this.radio,
          ...this.formData,
        });
      }
    },
    // 确定
    submitDialogForm() {
      console.log("formData", this.formData);
      if (this.radio === 2) {
        if (
          Number(this.realTenentType) !== 3 &&
          !this.formDataArea.areaCodeList
        ) {
          this.$message.warning("请选择区域！");
          return;
        }
      } else if (this.radio === 3) {
        if (!this.formData.schoolCode) {
          this.$message.warning("请选择学校！");
          return;
        }
      }
      if (this.radio === 2) {
        console.log(554455, this.formDataArea);
        this.SET_LOOKBOARD_INFO({
          kanbanType: this.radio,
          ...this.formDataArea,
        });
      } else if (this.radio === 3) {
        console.log(7766777, this.formData);
        this.SET_LOOKBOARD_INFO({
          kanbanType: this.radio,
          ...this.formData,
        });
      } else {
        this.SET_LOOKBOARD_INFO({
          kanbanType: this.radio,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #04ffff;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #418ee2;
  background: #418ee2;
}
/deep/ .el-radio__inner {
  background-color: #418ee2;
  border-color: #418ee2;
}
/deep/ .el-radio {
  color: #04ffff;
}
/deep/ .el-radio__inner::after {
  background-color: #04ffff;
  width: 6px;
  height: 6px;
}
/deep/ .el-input__icon {
  line-height: 0;
}
/deep/ .board-select .el-input__inner {
  padding: 0 25px 0 15px;
}
/deep/ .popper-class-select .el-select-dropdown__item.hover,
.popper-class-select .el-select-dropdown__item:hover {
  color: #04ffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
/deep/ .el-dialog__wrapper {
  backdrop-filter: saturate(150%) blur(4px);
  -webkit-backdrop-filter: saturate(150%) blur(4px);
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
