<template>
  <div class="pie-charts">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Chart,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.pie-charts {
  width: 100%;
}
</style>
