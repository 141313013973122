<template>
  <div class="day-class-content">
    <div
      v-for="(item, index) in classDurationList"
      :key="item.name"
      class="flex flex-m item-content"
    >
      <div :class="getBackgroundImage(index)" class="item-number">
        {{ index + 1 }}
      </div>
      <tooltip-over
        :popperClass="true"
        :content="item.name"
        class="item-name"
      ></tooltip-over>
      <!-- <div class="item-name single-line">{{ item.name }}</div> -->
      <div class="bg-content-item" :style="'width:' + totalWidth + 'px;'">
        <div :style="'width:' + totalWidth * item.scale + 'px;'">
          <div class="bg-content-item-real"></div>
        </div>
      </div>
      <div class="item-value">{{ toFixed5(item.time, 1) }}min</div>
    </div>
  </div>
</template>

<script>
import Chart from "./chart.vue";
import tooltipOver from "../../../biz-components/tooltipOver/index.vue";
import { toFixed5 } from "@/utils";
export default {
  data() {
    return {};
  },
  props: {
    totalWidth: {
      type: Number,
      default: 290,
    },
    classDurationList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    // Chart,
    tooltipOver,
  },
  mounted() {},
  methods: {
    toFixed5,
    getBackgroundImage(index) {
      switch (index) {
        case 0:
          return "bg-top1";
        case 1:
          return "bg-top2";
        case 2:
          return "bg-top3";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.day-class-content {
  padding-left: 26px;
  // padding-right: 30px;
  .item-content {
    padding-top: 18px;

    .item-number {
      width: 20px;
      height: 20px;
      // background: rgba(0, 186, 255, 0);
      // border-radius: 50%;
      box-sizing: content-box;
      // border: 1px solid #da3f16;
      // box-shadow: inset 0 0 2px 3px #da3f16;
      text-align: center;

      font-size: 14px;
      font-family: DIN;
      font-weight: 400;
      color: #abd6f0;
      line-height: 20px;
    }
    .item-name {
      width: 112px;
      line-height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #04ffff;
      margin-left: 11px;
    }
    .item-value {
      line-height: 20px;
      font-size: 14px;
      font-family: DIN;
      font-weight: 400;
      color: #abd6f0;

      margin-left: 16px;
    }
    .bg-top1 {
      background: url("../../../../assets/images/icon/top1.png") no-repeat;
      background-size: 100% 100%;
      font-size: 14px;
      font-family: DIN;
      font-weight: 400;
      color: #da3f16;
      line-height: 20px;
    }
    .bg-top2 {
      background: url("../../../../assets/images/icon/top2.png");
      background-size: 100% 100%;
      font-size: 14px;
      font-family: DIN;
      font-weight: 400;
      color: #da6120;
      line-height: 20px;
    }
    .bg-top3 {
      background: url("../../../../assets/images/icon/top3.png");
      background-size: 100% 100%;
      font-size: 14px;
      font-family: DIN;
      font-weight: 400;
      color: #e1ba44;
      line-height: 20px;
    }
  }

  .bg-content-item {
    background: #353e45;
    border-radius: 20px;
    height: 8px;
    margin-left: 2px;

    &-real {
      background: linear-gradient(90deg, #f3b229, #a56de7);
      border-radius: 4px;
      height: 8px;
      animation: realWidth 1s;
    }
    @keyframes realWidth {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    @-moz-keyframes realWidth /* Firefox */ {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    @-webkit-keyframes realWidth /* Safari and Chrome */ {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}
</style>
