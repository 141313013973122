<template>
  <div id="dayClassDuration" class="dayClassDuration">
    <!-- <div class="bg-color-black"> -->
    <div
      class="d-flex flex-title"
      :class="$i18n.locale === 'zh_CN' ? 'pop-zh' : 'pop-en'"
    >
      <span class="fs-xl text"
        >{{ $t("el.board.DurationDayStudent") }} (min)<hover-component
          style="text-align: left"
          :content="content[etype - 1]"
          name="question-circle"
        ></hover-component
      ></span>
    </div>

    <dv-border-box-8
      v-loading="loading"
      element-loading-background="rgba(0,0,0,.2)"
      class="dv-border"
      :color="['#3678C0']"
      :dur="0"
    >
      <div class="flex" style="padding-top: 25px">
        <xm-form
          ref="form"
          class="class-form flex"
          :form-items="formItems"
          :form-options="newFormOptions"
          :form-data="queryParams"
          @submit.native.prevent
        >
        </xm-form>

        <div @click="showMoreDialog" class="day-class-more">
          {{ $t("el.board.More") }}
        </div>
      </div>

      <div
        v-show="!showEmpty && !loading"
        :class="[$i18n.locale === 'zh_CN' ? '' : 'content-en']"
      >
        <div style="min-height: 190px">
          <day-class-duration
            id="dayClassDurationContent"
            class="dv-cap-chart"
            :classDurationList="dayClassTimeList"
          />
        </div>
        <div class="flex flex-b btn-bottom">
          <div class="btn-day-class">
            {{ $t("el.board.NumberCoursesPerson") }} {{ $t("el.common.colon")
            }}<span>{{ durationTimeTotal.courseNum }}</span>
          </div>
          <div class="btn-day-class-bg"></div>

          <div
            :class="[
              $i18n.locale === 'zh_CN' ? 'btn-day-class' : 'btn-day-class-2',
            ]"
          >
            {{ $t("el.board.PerCapitaDuration") }}{{ $t("el.common.colon")
            }}<span>{{ toFixed5(durationTimeTotal.time, 1) }}</span>
          </div>
          <div class="btn-day-class-bg2"></div>
        </div>
      </div>
      <empty-chart v-show="showEmpty && !loading"></empty-chart>
    </dv-border-box-8>
    <dialog-component
      class="day-class-table"
      @closeDialog="showDialog = false"
      v-if="showDialog"
    >
      <template slot="header">
        <p class="d-title">{{ $t("el.board.DurationDayStudent") }}</p>
      </template>
      <template slot="xm-table">
        <xm-table
          ref="xmTable"
          :fatch-method="fatchMethod"
          :form-items="formItemsDialog"
          :columns="columns"
          :formOptions="{
            hiddenDefaultFormBtn: true,
          }"
          :tableOptions="{
            stripe: true,
            border: false,
          }"
          :class="computedClassName"
          :showPagination="isShowPagination"
          :sreenEmpty="true"
          :defaultLimit="5"
          :autoLoad="false"
          @changeQueryParams="changeQueryParams"
        >
          <template slot="formBtn">
            <el-button
              type="primary"
              @click="query()"
              size="small"
              class="teachManage-tool-btnicon"
              >{{ $t("el.common.search") }}</el-button
            >
          </template>
        </xm-table>
      </template>
      <template slot="footer">
        <div v-show="isShowPagination" class="f-button">
          <el-button
            :loading="exportLoading"
            @click="exportData"
            type="primary"
            size="small"
            >{{ $t("el.common.export") }}
          </el-button>
        </div>
      </template>
    </dialog-component>
  </div>
  <!-- </div> -->
</template>

<script>
import hoverComponent from "@/components/echart/hover/index";
import DayClassDuration from "@/components/echart/histogram/dayClassDuration";
import XmForm from "../../../components/global/xm-form.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import dialogComponent from "@/components/echart/dialog/index";
import emptyChart from "@/components/echart/empty";
import { toFixed5 } from "@/utils";
import { handparams } from "../../../utils";
import { exportDurationData } from "@/api/screen";

export default {
  data() {
    var queryParams = {};
    // this.formItems &&
    //   this.formItems.forEach((item) => {
    //     queryParams[item.prop] = "";
    //   });
    return {
      showDialog: false,
      isShowPagination: false,
      showEmpty: false,
      content: [
        `${this.$t("el.board.AverageRange")}</br>${this.$t(
          "el.board.AverageClassDuration"
        )}</br>${this.$t(
          "el.board.PlatformAverageNumberCourses"
        )}</br>${this.$t("el.board.PlatformClassDuration")}</br>${this.$t(
          "el.board.PlatformDurationPerDay"
        )}`,
        `${this.$t("el.board.AverageRange")}</br>${this.$t(
          "el.board.AverageClassDuration"
        )}</br>${this.$t("el.board.AreaAverageNumberCourses")}</br>${this.$t(
          "el.board.AreaClassDuration"
        )}</br>${this.$t("el.board.AreaDurationPerDay")}`,
        `${this.$t("el.board.AverageRange")}</br>${this.$t(
          "el.board.AverageClassDuration"
        )}</br>${this.$t("el.board.SchoolAverageNumberCourses")}</br>${this.$t(
          "el.board.SchoolClassDuration"
        )}</br>${this.$t("el.board.SchoolDurationPerDay")}`,
        `${this.$t("el.board.AverageRange")}</br>${this.$t(
          "el.board.AverageClassDuration"
        )}</br>${this.$t(
          "el.board.PlatformAverageNumberCourses"
        )}</br>${this.$t("el.board.PlatformClassDuration")}</br>${this.$t(
          "el.board.PlatformDurationPerDay"
        )}`,
      ],
      queryParams, // 查询参数
      nowQueryParams: {}, // 当前查询参数
      newFormOptions: {},
      commonColumns: [],
      gradeColumns: [
        {
          label: this.$t("el.board.Ranking"),
          prop: "sort",
        },
        {
          label: this.$t("el.schoolResourceManage.grade"),
          prop: "name",
        },
        {
          label: this.$t("el.board.DurationPerCapita"),
          prop: "time",
          formatter(row, column, cellValue) {
            return toFixed5(cellValue, 1);
          },
        },
      ],
      classColumns: [
        {
          label: this.$t("el.board.Ranking"),
          prop: "sort",
        },
        {
          label: this.$t("el.planCourse.class"),
          prop: "name",
        },
        {
          label: this.$t("el.board.DurationPerCapita"),
          prop: "time",
          formatter(row, column, cellValue) {
            return toFixed5(cellValue, 1);
          },
        },
      ],
      areaColumns: [
        {
          label: this.$t("el.board.Ranking"),
          prop: "sort",
        },
        {
          label: this.$t("el.board.AreaName"),
          prop: "name",
        },
        {
          label: this.$t("el.board.DurationPerCapita"),
          prop: "time",
          formatter(row, column, cellValue) {
            return toFixed5(cellValue, 1);
          },
        },
      ],
      schoolAreaColumns: [
        {
          label: this.$t("el.board.Ranking"),
          prop: "sort",
        },
        {
          label: this.$t("el.board.SchoolName"),
          prop: "name",
        },
        {
          label: `所属区域`,
          prop: "areaName",
        },
        {
          label: this.$t("el.board.DurationPerCapita"),
          prop: "time",
          formatter(row, column, cellValue) {
            return toFixed5(cellValue, 1);
          },
        },
      ],
      tableParams: {},
      exportLoading: false,
    };
  },
  props: {
    etype: {
      type: Number,
      default: 1,
    },
    schoolCode: {
      type: [String, Number],
      default: "",
    },
    areaCode: {
      type: [String, Number],
      default: "",
    },
    orgCode: {
      type: [String, Number],
      default: "",
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    queryParams: {
      handler(val, old) {
        console.log("queryParams.sort", val);
        this.$emit("onChangeData", val);
      },
      deep: true,
    },
    dayClassTimeList: {
      handler(val, old) {
        if (val.length === 0) {
          this.showEmpty = true;
        } else {
          this.showEmpty = false;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["dayClassTimeList"]),
    ...mapState({
      durationTimeTotal: (state) => state.board.durationTimeTotal,
    }),
    // ...mapState("board",["durationTimeTotal"]),
    trueCode() {
      if (this.etype === 2) {
        return this.areaCode;
      } else if (this.etype === 3) {
        return this.schoolCode;
      } else if (this.etype === 4) {
        return this.orgCode;
      } else {
        return "";
      }
    },
    placeSelect() {
      let a;
      switch (this.etype) {
        case 1:
        case 4:
          a = [
            {
              value: 4,
              label: this.$t("el.board.AccordingArea"),
            },
            {
              value: 3,
              label: this.$t("el.board.AccordingSchool"),
            },
            // {
            //   value: 1,
            //   label: "按年级",
            // },
          ];
          break;
        case 2:
          a = [];
          break;
        case 3:
          a = [
            {
              value: 1,
              label: this.$t("el.board.AccordingGrade"),
            },
            {
              value: 2,
              label: this.$t("el.board.AccordingClass"),
            },
          ];
          break;

        default:
          a = [];
          break;
      }
      return a;
    },
    formItemsDialog() {
      let placeItem = {
        itemType: "select",
        prop: "type",
        placeholder: this.$t("el.common.pleaseSelect"),
        label: "",
        options: this.placeSelect,
        selectDefault: true,
        "popper-class": "popper-class-dialog",
        "popper-append-to-body": false,
        // labelWidth: "120px",
        // clearable: true,
        itemStyle: `width:${this.$t("el.common.width90Auto")};margin-left:14px`,
        // formStyle: "width:100px;",
      };
      let inputItem = {
        itemType: "input",
        prop: "name",
        placeholder: this.$t("el.common.pleaseEnter"),
        label: ``,
        itemStyle:
          "width:142px;height:32px;  background-color: rgba(19, 56, 91, 0.76);border: 1px solid #3678c0;height: 30px !important;",
        labelWidth: "84px",
        clearable: true,
      };
      if (this.etype === 2) {
        return [
          {
            itemType: "input",
            prop: "name",
            placeholder: this.$t("el.common.pleaseEnter"),
            label: this.$t("el.board.SchoolName"),
            itemStyle:
              "width:142px;height:30px;  background-color: rgba(19, 56, 91, 0.76);border: 1px solid #3678c0;height: 30px !important;",
            labelWidth: "84px",
            clearable: true,
          },
        ];
      } else {
        return [placeItem, inputItem];
      }
    },

    columns() {
      if (this.etype === 2) {
        return [
          {
            label: this.$t("el.board.Ranking"),
            prop: "sort",
          },
          {
            label: this.$t("el.board.SchoolName"),
            prop: "name",
          },
          {
            label: this.$t("el.board.DurationPerCapita"),
            prop: "time",
          },
        ];
      } else if (this.etype === 3) {
        return this.commonColumns;
      } else {
        console.log(" this.commonColumns", this.commonColumns);
        return this.commonColumns;
      }
    },

    computedClassName() {
      if (!this.isShowPagination) {
        return "empty-data";
      } else {
        return "";
      }
    },

    formItems() {
      let rankItem = {
        itemType: "select",
        prop: "sort",
        placeholder: this.$t("el.common.pleaseSelect"),
        label: "",
        selectDefault: true,
        options: [
          {
            value: "",
            label: this.$t("el.board.TopFive"),
          },
          {
            value: 1,
            label: this.$t("el.board.LastFive"),
          },
        ],
        "popper-class": "popper-class",
        "popper-append-to-body": false,
        // labelWidth: "120px",
        // clearable: true,
        itemStyle: `width:${this.$t("el.common.width90_120")};margin-left:14px`,
        // formStyle: "width:100px;",
      };
      let placeItem = {
        itemType: "select",
        prop: "type",
        placeholder: this.$t("el.common.pleaseSelect"),
        label: "",
        options: this.placeSelect,
        selectDefault: true,
        "popper-class": "popper-class",
        "popper-append-to-body": false,
        // labelWidth: "120px",
        // clearable: true,
        itemStyle: `width:${this.$t("el.common.width90Auto")};margin-left:14px`,
        // formStyle: "width:100px;",
      };
      if (this.etype === 2) {
        return [rankItem];
      }
      return [placeItem, rankItem];
    },
  },
  created() {
    // this.$set(this.queryParams, "type", this.etype === 1 ? 4 : this.etype = 3 ? 1 : null);
    // this.$set(this.queryParams, "sort", "");
  },
  methods: {
    toFixed5,
    ...mapActions(["exportDurationTime"]),
    // 获取表格数据
    fatchMethod(params) {
      console.log("params111", params);
      delete params.offset;
      delete params.limit;
      delete params.total;
      delete params.page;
      this.tableParams = params;
      let form = {
        kanbanType: this.etype,
        code: this.trueCode,
        ...params,
        // offset: params.offset,
        // limit: params.limit,
        // name: params.name,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      return this.$store
        .dispatch("getDayClassTimesTable", handparams(form))
        .then((res) => {
          if (res.data.records.length === 0) {
            this.isShowPagination = false;
          } else {
            this.isShowPagination = true;
          }
          return res;
        })
        .catch(() => {
          this.isShowPagination = false;
        });
    },
    changeQueryParams(data) {
      console.log(data, 78767);
      if (data.type !== this.tableParams.type) {
        this.$refs["xmTable"].queryParams.name = "";
        this.query();
      }
    },
    showMoreDialog() {
      let that = this;
      this.showDialog = true;
      this.$nextTick(() => {
        that.$set(
          that.$refs["xmTable"].queryParams,
          "type",
          that.queryParams.type
        );
        // if (this.etype === 1) {
        //   if (this.queryParams.type === 4) {
        //     that.commonColumns = that.areaColumns;
        //   }else if(this.queryParams.type === 3) {
        //     that.commonColumns = that.schoolAreaColumns;
        //   }
        // }else if (this.etype === 3) {
        //   if (this.queryParams.type === 1) {
        //     that.commonColumns = that.gradeColumns;
        //   }else if(this.queryParams.type === 2) {
        //     that.commonColumns = that.classColumns;
        //   }
        // }
        that.query();
      });
    },
    query() {
      let queryParams = this.$refs["xmTable"].queryParams;
      console.log("queryParams===", queryParams);
      if (this.etype === 1 || this.etype === 4) {
        if (queryParams.type === 4) {
          this.commonColumns = this.areaColumns;
        } else if (queryParams.type === 3) {
          this.commonColumns = this.schoolAreaColumns;
        }
      } else if (this.etype === 3) {
        if (queryParams.type === 1) {
          this.commonColumns = this.gradeColumns;
        } else if (queryParams.type === 2) {
          this.commonColumns = this.classColumns;
        }
      }
      this.$refs["xmTable"].query();
    },
    // 导出
    exportData() {
      // console.log(this.$refs["xmTable"].getQueryParams());
      this.exportLoading = true;
      let params = this.$refs["xmTable"].getQueryParams();
      let form = {
        kanbanType: this.etype,
        startDate: this.startDate,
        endDate: this.endDate,
        name: params.name,
        type: params.type,
      };
      if (this.etype !== 1) {
        form.code = this.trueCode;
      }
      // this.exportDurationTime(handparams(form));
      exportDurationData(form)
        .then((res) => {
          let url = window.URL.createObjectURL(res);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          console.log(url, "url=====");
          link.setAttribute(
            "download",
            form.startDate.substring(0, 10) +
              this.$t("el.common.to") +
              form.endDate.substring(0, 10) +
              this.$t("el.board.DurationDayStudent") +
              ".xlsx"
          );
          console.log("link====", link);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error("捕获到错误：", err);
          if (err.response) {
            console.error("响应数据：", err.response.data);
            console.error("响应状态码：", err.response.status);
          }
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
  },
  components: {
    DayClassDuration,
    XmForm,
    hoverComponent,
    dialogComponent,
    emptyChart,
  },
};
</script>

<style lang="scss" scoped>
.dayClassDuration {
  $box-height: 305px;
  $box-width: 100%;
  width: $box-width;
  height: $box-height;
  border-radius: 5px;
  position: relative;
  padding: 5px;
  margin-top: 10px;
  .class-form {
    height: fit-content;
  }
  .dv-border {
    // padding-top: 23px;
    .day-class-more {
      width: 50px;
      height: 16px;
      line-height: 16px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-left: auto;
      margin-right: 16px;
      cursor: pointer;
    }
    .btn-bottom {
      margin-top: 20px;
      padding-left: 32px;
      padding-right: 32px;
      position: relative;
      .btn-day-class-bg {
        position: absolute;
        width: 235px;
        height: 32px;
        // padding: 1px 2.5px;
        left: 29.5px;
        top: -1px;
        background: linear-gradient(to left, #029a9f, #029a9f) no-repeat left
            top,
          linear-gradient(to left, #029a9f, #029a9f) no-repeat right top,
          linear-gradient(to left, #029a9f, #029a9f) no-repeat left bottom,
          linear-gradient(to left, #029a9f, #029a9f) no-repeat right bottom,
          transparent;
        background-size: 5px 4px, 5px 4px, 5px 4px, 5px 4px;
      }
      .btn-day-class-bg2 {
        position: absolute;
        width: 235px;
        height: 32px;
        // padding: 1px 2.5px;
        right: 29.5px;
        top: -1px;
        background: linear-gradient(to left, #029a9f, #029a9f) no-repeat left
            top,
          linear-gradient(to left, #029a9f, #029a9f) no-repeat right top,
          linear-gradient(to left, #029a9f, #029a9f) no-repeat left bottom,
          linear-gradient(to left, #029a9f, #029a9f) no-repeat right bottom,
          transparent;
        background-size: 5px 4px, 5px 4px, 5px 4px, 5px 4px;
      }
      .btn-day-class {
        position: relative;
        width: 230px;
        height: 30px;
        background: rgba(26, 54, 73, 0.57);
        // text-align: center;
        box-sizing: border-box;
        padding-left: 29px;
        line-height: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
      }
      .btn-day-class :nth-child(1) {
        font-size: 16px;
        font-family: DIN;
        font-weight: 400;
        color: #04ffff;
        line-height: 30px;
      }
      .btn-day-class-2 {
        position: relative;
        width: 230px;
        height: 30px;
        background: rgba(26, 54, 73, 0.57);
        // text-align: center;
        box-sizing: border-box;
        padding-left: 29px;
        line-height: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
      }
      .btn-day-class-2 :nth-child(1) {
        font-size: 16px;
        font-family: DIN;
        font-weight: 400;
        color: #04ffff;
        line-height: 30px;
      }
    }
    .content-en {
      .btn-bottom {
        padding-left: 12.5px;
        // padding-right: 32px;
        .btn-day-class-bg {
          position: absolute;
          width: 275px;
          height: 32px;
          // padding: 1px 2.5px;
          left: 9.5px;
          top: -1px;
          background: linear-gradient(to left, #029a9f, #029a9f) no-repeat left
              top,
            linear-gradient(to left, #029a9f, #029a9f) no-repeat right top,
            linear-gradient(to left, #029a9f, #029a9f) no-repeat left bottom,
            linear-gradient(to left, #029a9f, #029a9f) no-repeat right bottom,
            transparent;
          background-size: 5px 4px, 5px 4px, 5px 4px, 5px 4px;
        }
        .btn-day-class-bg2 {
          position: absolute;
          width: 235px;
          height: 32px;
          // padding: 1px 2.5px;
          right: 29.5px;
          top: -1px;
          background: linear-gradient(to left, #029a9f, #029a9f) no-repeat left
              top,
            linear-gradient(to left, #029a9f, #029a9f) no-repeat right top,
            linear-gradient(to left, #029a9f, #029a9f) no-repeat left bottom,
            linear-gradient(to left, #029a9f, #029a9f) no-repeat right bottom,
            transparent;
          background-size: 5px 4px, 5px 4px, 5px 4px, 5px 4px;
        }
        .btn-day-class {
          width: 270px;

          padding-left: 9px;
        }
        .btn-day-class :nth-child(1) {
          font-size: 16px;
          font-family: DIN;
          font-weight: 400;
          color: #04ffff;
          line-height: 30px;
        }
        .btn-day-class-2 {
          width: 230px;

          box-sizing: border-box;
          padding-left: 9px;
          margin-left: 9px;
        }
        .btn-day-class-2 :nth-child(1) {
          font-size: 16px;
          font-family: DIN;
          font-weight: 400;
          color: #04ffff;
          line-height: 30px;
        }
      }
    }
  }
  .bg-color-black {
    // padding: 0 5px 0px;
    width: $box-width;
    height: $box-height;
    border-radius: 10px;
    // padding-top: 30px;
  }
  .flex-title {
    width: max-content;
    z-index: 999;
    position: absolute;
    top: -2%;
    // left: calc(50% - 100px);
    transform: translateX(-50%);
    left: 50%;
    background: #1b2a5c;
    // height: 25px;
    line-height: 25px;
    padding-left: 20px;
    padding-right: 14px;
    max-width: 70%;
    // text-align: center;
    .text {
      font-size: 16px;
      font-weight: 500;
      color: #04ffff;
      text-align: center;
      word-break: break-all;
    }
  }
  .pop-zh {
    ::v-deep .icon-component-tooltip {
      right: -140px;
    }
  }
  .pop-en {
    ::v-deep .icon-component-tooltip {
      right: -90px;
    }
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
  ::v-deep .teachManage-page {
    display: none;
  }
  ::v-deep .el-table__body-wrapper {
    height: 230px;
    overflow-y: auto;
  }
  .f-button {
    ::v-deep .el-button {
      > span {
        width: auto !important;
      }
    }
  }
}
</style>
<style lang="less">
.dayClassDuration {
  .popper-class {
    background: rgba(9, 31, 47, 0.92) !important;
    border: 1px solid #3c87d8 !important;
    height: fit-content !important;
  }
  .popper__arrow {
    top: -7px !important;
    left: 65% !important;
    margin-right: 3px;
    border-top-width: 0;
    border-bottom-color: #3c87d8 !important;
  }
  .popper__arrow::after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border-bottom-color: #091f2f !important;
  }

  .popper-class-dialog {
    background: rgba(9, 31, 47, 0.92) !important;
    border: 1px solid #3c87d8 !important;
    height: fit-content !important;
  }
}
</style>
<style lang="less" scoped>
.dayClassDuration {
  /deep/ .empty-chart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 35px;
  }
  .day-class-table {
    /deep/ .el-input__inner {
      -webkit-appearance: none;
      background-color: transparent;
      background-image: none;
      border-radius: 4px;
      border: 0px solid #dcdfe6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #ffffff;
      display: inline-block;
      font-size: inherit;
      height: 30px !important;
      line-height: 30px !important;
      outline: 0;
      padding: 0 15px;
      -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }

    /deep/ .el-form-item__label {
      font-size: 14px;
      font-weight: 300;
      color: #04ffff;
      line-height: 30px;
    }

    /deep/ .echart-dialog-content .el-form {
      border-bottom: 1px solid rgba(80, 172, 254, 0.34);
      padding-bottom: 22px;
    }
  }

  /deep/ .icon-component-question-icon {
    color: #99c0d8;
    margin-left: 8px;
    vertical-align: bottom;
    width: 16px;
    height: 16px;
    margin-bottom: 5px;
  }

  /deep/ .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 16px !important;
  }
  /deep/ .el-form-item__content {
    line-height: 16px;
    position: relative;
    font-size: 14px;
  }
  /deep/ .el-input__inner {
    -webkit-appearance: none;
    background-color: transparent;
    background-image: none;
    border-radius: 4px;
    border: 0px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-block;
    font-size: inherit;
    height: 16px !important;
    line-height: 16px !important;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
  /deep/ .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #154263 !important;
  }
  /deep/ .el-select-dropdown__item.selected {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #04ffff;
  }
  /deep/ .el-select-dropdown__item {
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 34px;
    line-height: 34px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #abd6f0;
  }
  /deep/ .el-select-dropdown {
    top: auto !important;
    left: auto !important;
    border: 1px solid rgb(60, 135, 216);
    &__empty,
    .el-select-dropdown__list {
      line-height: 20px;
      background: #091f2f;
      color: #abd6f0;
      border-radius: 3px;
    }
    .el-select-dropdown__item {
      color: #abd6f0;
      font-weight: bold;
      font-size: 12px;
      &.hover {
        background-color: rgba(#154263, 68%);
        color: #04ffff !important;
      }
    }
    .el-select-dropdown__item.selected {
      background-color: rgba(#154263, 68%);
      color: #04ffff !important;
    }
    .popper__arrow {
      border-bottom-color: #3c87d8;
      &::after {
        border-bottom-color: #091f2f;
      }
    }
  }
}
</style>
